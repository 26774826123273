import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout";
import usersService from "../../../services/UsersService";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [validation, setValidation] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false);
  const user = new usersService()

  const [email, setEmail] = useState("");
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  function validationChecker(email) {
    const emailCheck = /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/;
    if (email === "") {
      setValidation("");
    } else if (!email.match(emailCheck)) {
      setValidation('Please input a valid email address');
    } else {
      setButtonDisabled(false)
    }
  }

  const handleSubmit = async() => {
    const resetEmail = {
      username: email,
      stage: 100
    }
    const reset = await user.resetPassword(resetEmail);
    setLoading(true)
    
    const {raw_data, uuid} = reset.data
    localStorage.setItem('reset_uuid', uuid)
    localStorage.setItem('reset_raw', JSON.stringify(raw_data))
    if (reset.status == 200){
      setTimeout(() => {
        navigate("/verify-you", {replace : true});
        setLoading(false)
      }, 2000);
    }else{
      setValidation('An error occurred')
    }
  }
  
  useEffect(() => {
    validationChecker(email)
  }, [email])

  return (
    <>
      <AuthLayout
        slogan="Welcome to myFit"
      >
        <div className="content-wrapper xl:bg-white dark:bg-dark-white   xl:px-[70px] w-full sm:w-auto   2xl:px-[100px] h-[818px] rounded-xl ">
          <div className="flex flex-col justify-center w-full h-full px-5">
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
              <h1 className="sm:text-5xl text-4xl font-bold sm:leading-[74px]   text-dark-gray dark:text-white">
                Forget Password
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-1 ml-0">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <div className="input-item mb-5">
                <InputCom
                  placeholder="example@quomodosoft.com"
                  label="Email Address"
                  name="email"
                  type="email"
                  iconName="message"
                  inputHandler={handleEmail}
                  value={email}
                />
                {validation && <p className="my-5 text-center font-light italic subpixel-antialiased tracking-wide text-red-500">{validation}</p>}
              </div>

              <div className="signin-area mb-3.5">

                <button
                  className="w-full rounded-[50px] h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                  disabled={buttonDisabled}
                  onClick={handleSubmit}
                >
                  {loading ? <div className="signup btn-loader"></div> : <span>Send Code</span>}
                </button>

                <Link to="/"
                  className="mt-5 cursor-default font-bold flex justify-center subpixel-antialiased tracking-wide text-white items-center h-[58px] rounded-[50px] bg-[#1a3544a2]"
                >
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
