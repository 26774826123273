import React, {useEffect} from "react";
import Home from "../components/Home";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";

export default function HomePages() {

  const { drawer } = useSelector((state) => state.drawer);
  return (
    <>
      <Home />
    </>
  );
}
