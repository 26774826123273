import React from "react";
import { Link } from "react-router-dom";
import UpdateTable from "./../UpdateTable";
import TotalRecentHx from "./../TotalRecentHx";

export default function HmLayout1({ className }) {
  return (
    <div>
        <TotalRecentHx className="mb-10"/> 
        <UpdateTable className="mb-10"/>
    </div>
  );
}
