import React, { useEffect, useState } from "react";

import country1 from "../../assets/images/country-1.png";
import country2 from "../../assets/images/country-2.png";
import country3 from "../../assets/images/country-3.png";
import SellMonthStatics from "../Charts/SellMonthStatics";
import SellHistoryWidget from "../Home/SellHistoryWidget";
import CurrentBalanceWidget from "../MyWallet/CurrentBalanceWidget";
import Layout from "../Partials/Layout";
//import SellAnaliseStatics from "./SellAnaliseStatics";
import SellProductHistoryTable from "./SellProductHistoryTable";
import SubScriptionTop from "./SubScriptionTop";

import SiteService from "../../services/SiteService";
import PricingListTable from "./PricingListTable";
export default function Subscriptions() {
  const site_api = new SiteService();
  const [pricingData, setPricingData] = useState([]);
  const getPricingData = async () => {
    try {
      const res = await site_api.priceData();
      if (res.status == 200) {
        //debugger;
        console.log("PPPP",res.data.pricing);
        setPricingData([{ data: res.data.pricing
        }]);
        return;
      }
    } catch (error) {
      // setIsLoading(false)
    }
  };
  
  useEffect(() => {
    getPricingData();
  }, []);
 // debugger;
 console.log("FFFFF",pricingData);
 console.log("AAAAA- ",pricingData[0]);
  return (
    <>
      <Layout>
        <SubScriptionTop />
        <div className="sell-page-wrapper w-full mb-10">
          <div className="main-wrapper w-full">
            <div className="current_balance-bit-sell-widget w-full lg:h-[436px] mb-11">
              <div className="w-full h-full lg:flex lg:space-x-7">
                {/* style={{ width: "calc(50% - 15px)" }} */}
{

pricingData &&
pricingData[0]?.data?.length > 0 && pricingData[0].data.map((price,index)=>(
  <>
    <div className="lg:w-1/3 h-full mb-10 lg:mb-0">
      <div className="sell-month-analytic-card w-full h-full rounded-xl overflow-hidden relative">
        {/* heading */}
        <div className="w-full h-16 bg-gold flex pl-7 items-center">
          <h1 className="text-xl font-medium tracking-wide text-white">
            {price.title}
          </h1>
        </div>
        <div className="w-full h-full flex flex-col justify-between bg-white dark:bg-dark-white  ">
          <div className="w-full px-5 pt-5">
            <p className="text-26 font-bold text-dark-gray dark:text-white tracking-wide">
            {price.price}
            </p>
          </div>
          <div className="month-statics w-full lg:h-[205px] h-full lg:absolute bottom-0 left-0 transform scale-[1.08]">
            <PricingListTable
            priceDetail={price}
            setRGBColor="rgba(242, 153, 74)" />
          </div>
        </div>
      </div>
    </div>
  </>
  ))
}


              </div>
            </div>

          
          </div>
        </div>
      </Layout>
    </>
  );
}
