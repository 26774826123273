import React, { useEffect } from "react";

export default function Otp({handleChange, value}) {
  useEffect(() => {
    const otp = document.querySelector("#otp-inputs");
    // eslint-disable-next-line no-restricted-syntax
    for (const pin of otp.children) {
      // eslint-disable-next-line no-loop-func
      pin.onkeyup = (value) => {
        if(pin.children){
          if(value.key === '' || value.key === ' ' || value.key === 'ArrowRight' || value.key === 'ArrowLeft' || value.key === 'ArrowUp' || value.key === 'ArrowDown' || value.key === 'Tab') return;
          if(value.key === 'Backspace'){
            if(pin.previousSibling){
              pin.previousSibling.children.otp.focus();
            } else {return;}
          } else {
            pin.nextSibling.children.otp.focus();
          }
        }
      };  
    }    
  }, []);
  
  return (
    <>
      <div
        className="otp-input-com flex sm:space-x-5 space-x-3 justify-center mb-8"
        id="otp-inputs"
      >
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px]  overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base leading-14 text-center items-center text-2xl font-bold text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
            name='value_one'
            value={value.value_one}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px] overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
            name='value_two'
            value={value.value_two}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px] overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold  leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
            name='value_three'
            value={value.value_three}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px]  overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
            name='value_four'
            value={value.value_four}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px]  overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold leading-14 text-center  text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
            name='value_five'
            value={value.value_five}
            onChange={handleChange}
          />
        </div>
        <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  sm:w-14 sm:h-14 w-12 h-12 rounded-[50px] overflow-hidden relative ">
          <input
            className="input-field placeholder:text-base text-2xl font-bold  leading-14 text-center text-dark-gray dark:text-white w-full h-full  bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-2 focus:outline-none"
            type="text"
            maxLength={1}
            id="otp"
            name='value_six'
            value={value.value_six}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}
