import React, { useEffect, useState } from "react";
import usersService from "../../../services/UsersService";
import dataImage1 from "../../../assets/images/data-table-user-1.png";
import dataImage2 from "../../../assets/images/data-table-user-2.png";
import dataImage3 from "../../../assets/images/data-table-user-3.png";
import dataImage4 from "../../../assets/images/data-table-user-4.png";
import SelectBox from "../../Helpers/SelectBox";

export default function UserRecentTracking() {
  const api = new usersService();
  const [isLoading, setIsLoading] = useState(true)
  const [userTrackingHx, setUserTrackingHx] = useState([]);
  const getUserTrackingHx = async () => {
    try {
      const res = await api.getUserTrackingHistory();
      if(res.status == 200){
        setUserTrackingHx([
          {data: res.data.tracking_data},
        ]);
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }catch(error) {
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    getUserTrackingHx();
  }, []);


  return (
    <div className="currency-statics w-full mb-11">
      <div className="w-full bg-white dark:bg-dark-white   rounded-2xl p-7">
        <div className="flex flex-col justify-between h-full">
          <div className="content sm:flex justify-between items-center mb-5">
            <div>
              <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
                Recent Records
              </h1>
            </div>
          </div>
          
          <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29]  default-border-b dark:border-[#5356fb29] ottom ">
              <td className="py-4">All Tracking</td>
              <td className="py-4 text-center">Value</td>
              <td className="py-4 text-center">Bits</td>
              <td className="py-4 text-center">Time</td>
              <td className="py-4 text-right">Status</td>
            </tr>
            {userTrackingHx[0]?.data?.length > 0
              ? userTrackingHx[0].data.map((track, index) => (
                  <tr className="bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29]  hover:bg-gray-50">
                    <td className=" py-4">
                      <div className="flex space-x-2 items-center">
                        <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                          <img
                            src={dataImage2}
                            alt="data"
                            className="w-full h-full"
                          />
                        </div>
                        <div className="flex flex-col">
                          <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                            {track.code}
                          </h1>
                          <span className="text-sm text-thin-light-gray">
                            Added{" "}
                            <span className="text-purple"> {track.added}</span>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-center py-4 px-2">
                      <div className="flex space-x-1 items-center justify-center">
                        <span>
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
                              fill="#627EEA"
                            />
                            <path
                              d="M9.28125 2.25V7.23937L13.4983 9.12375L9.28125 2.25Z"
                              fill="white"
                              fillOpacity="0.602"
                            />
                            <path
                              d="M9.28012 2.25L5.0625 9.12375L9.28012 7.23937V2.25Z"
                              fill="white"
                            />
                            <path
                              d="M9.28125 12.3582V15.7483L13.5011 9.91016L9.28125 12.3582Z"
                              fill="white"
                              fillOpacity="0.602"
                            />
                            <path
                              d="M9.28012 15.7483V12.3576L5.0625 9.91016L9.28012 15.7483Z"
                              fill="white"
                            />
                            <path
                              d="M9.28125 11.572L13.4983 9.12348L9.28125 7.24023V11.572Z"
                              fill="white"
                              fillOpacity="0.2"
                            />
                            <path
                              d="M5.0625 9.12348L9.28012 11.572V7.24023L5.0625 9.12348Z"
                              fill="white"
                              fillOpacity="0.602"
                            />
                          </svg>
                        </span>
                        <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                          {track.val1} {track.munit}
                        </span>
                      </div>
                    </td>
                    <td className="text-right py-4 px-2">
                      <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                        .
                      </span>
                    </td>
                    <td className="text-right py-4 px-2">
                      <span className="text-base text-thin-light-gray whitespace-nowrap">
                        {track.event_time}
                      </span>
                    </td>
                    <td className="text-right py-4 px-2">
                      <button
                        type="button"
                        className="text-sm text-white bg-light-green px-2.5 py-1.5 rounded-full"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>

        </div>
      </div>


    

    </div>
  );
}
