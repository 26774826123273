import Axios from "axios";

class usersService {
    constructor() {
        console.log("Er are here anyway");
    }

    // Reset Password
    resetPassword(reqData) {
        return this.postAuxEnd('/resetpass', reqData);
    }

    resetProfilePassword(reqData){
        return this.postAuxEnd('/resetpass-profile', reqData);
    }

    logInUser(reqData) {
        localStorage.setItem("session_token", ``);
        return this.postAuxEnd("/login", reqData);
    }

     //END POINT TO ADD REMINDER
     addReminder(reqData){
        return this.postAuxEnd("/editreminder", reqData);
    }

    //SIGNUP AUTH
    signupUser(reqData){
        return this.postAuxEnd("/account", reqData);
    }

    getUserReminders(){
        var reqData = {
            member_id: localStorage.getItem("member_id")
        };
        return this.getAuxEnd("/reminders", reqData);
    }

    getUserHomeStats(){
        var reqData = {
            member_id: localStorage.getItem("member_id")
        };
        return this.getAuxEnd("/home-stats", reqData);
    }

    getUserLoginHistory(){
        var reqData = {
            member_id: localStorage.getItem("member_id")
        };
        return this.getAuxEnd("/loginhx", reqData);
      }
      getUserTrackingHistory(){
        var reqData = {
            member_id: localStorage.getItem("member_id"),
            member_uuid: localStorage.getItem("member_uuid")
        };
        return this.getAuxEnd("/trackinghx", reqData);
      }

       //END POINT TO POPULATE MODE AND CATEGORY ON ADD REMINDER PAGED
        getUserModeCategory(path){
            var reqData = {
                member_id: localStorage.getItem("member_id")
            };
            return this.getAuxEnd(`/${path}`, reqData);
        }

      getUserResources(){
        var reqData = {
            member_id: localStorage.getItem("member_id")
        };
        return this.getAuxEnd("/resources", reqData);
      }

      refreshTrackCategory(){
        console.log("IMPLEMENT LOCAL STORAGE HERE - no need for server gab everytime");
        return this.getTrackCategory();
      }
      getTrackCategory(){
        var reqData = {
            member_id: localStorage.getItem("member_id")
        };
        return this.getAuxEnd("/trackcategory", reqData);
      }
    //----------------------------------------  -----
    //----------------------------------------  -----
    // Unified call below
    //----------------------------------------  -----
    //----------------------------------------  -----
    getAuxEnd(uri, reqData) {
        const session_token = localStorage.getItem("session_token");
        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Basic ${session_token}`,
            }
        };
        const endPoint = process.env.REACT_APP_USERS_ENDPOINT + uri;
        return Axios.get(endPoint,{
               params: {
                reqData
               }})
            .then((response) => {
                 console.log("~~~~~~~ Toks2 GET ~~~~~~~~");
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }


    postAuxEnd(uri, reqData) {
        const endPoint = process.env.REACT_APP_USERS_ENDPOINT + uri;
        const session_token = localStorage.getItem("session_token");
        let axiosConfig = {
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Basic ${session_token}`,
            }
        };
      //  Axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
      //  Axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'; //,axiosConfig
        return Axios.post(endPoint, reqData)
            .then((response) => {
                console.log(response);
                // res = response;
                console.log("~~~~~~~ Toks2 POST ~~~~~~~~");
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log("ERROR-------------------------------------------------------");
                    console.log(error.response.status, 'err');
                    console.log("ERROR-------------------------------------------------------");
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log("ERROR2-------------------------------------------------------");
                    console.log(error.request);
                    console.log("ERROR2-------------------------------------------------------");
                } else {
                    //an error occurred when setting up the request
                    console.log("ERROR3-------------------------------------------------------");
                    console.log(error.message);
                    console.log("ERROR3-------------------------------------------------------");
                }
            });
    }
}

export default usersService;
