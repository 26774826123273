import React from "react";
import TrackAction from "../components/Tracking/TrackAction";

export default function TrackActionPage() {
  return (
    <>
      <TrackAction />
    </>
  );
}
