import React, {useEffect} from "react";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AuthRoute = ({ redirectPath = "/login", children }) => {
  const isLogin = localStorage.getItem("email");
  const profile = localStorage.getItem("profile")

  const navigate = useNavigate();
  const { pathname } = useLocation();


  //FUNCTION TO EXPIRE SESSION AFETR 5 MINUTES AND REDIRECT USER TO LOGIN PAGE
  const expireSession = () => {
    localStorage.removeItem("email");
    localStorage.removeItem('session_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('member_id');
    localStorage.removeItem('member_uuid');
    localStorage.removeItem('status');
    localStorage.removeItem('layout');
    localStorage.removeItem('measurement_units');
    navigate("/login", { replace: true }); // redirects user to login page after session expires
  };

   //FUNCTION TO REMIND USER OF EXPIRY TIME
  const expireSessionReminder = () => {
    toast.success("session is about to expire", {
      icon: `🙂`,
    });
  }


  useEffect(()=>{
    let sessionExpReminder = setTimeout(()=>{ //reminds user of session expiry after 8 mins
      expireSessionReminder()
    }, 480000)

    let timeForSessionExpiry = setTimeout(()=>{ //expire session after 10 mins
      expireSession()
    }, 600000) 

    return ()=>{ // clears timeout side effect7
      clearTimeout(sessionExpReminder)
      clearInterval(timeForSessionExpiry)
    }
  },[pathname])


  if (!isLogin || !profile || (typeof JSON.parse(profile) == 'object' && JSON.parse(profile).firstname == undefined)) {
    return <Navigate to={redirectPath} replace />;
  }
  return children || <Outlet />;
};

export default AuthRoute;