import React, { useEffect, useState } from "react";
import HistoryAnalyticsCard from "../Cards/HistoryAnalyticsCard";
import SellHistoryMarketVisitorAnalytic from "../Home/SellHistoryMarketVisitorAnalytic";
import Layout from "../Partials/Layout";
import TrackingTable from "./TrackingTable";
import MarketHistorySection from "./MarketHistorySection";
import TrackCategory from "./TrackCategory";
import datas from "../../data/product_data.json";
import usersService from "../../services/UsersService";


export default function Tracking(props) {
  console.log("IN TRACKING COMPO", props);
  const trending = datas.datas;

  const api = new usersService();
  const [isLoading, setIsLoading] = useState(true)
  const [userTrackingHx, setUserTrackingHx] = useState([]);
  const getUserTrackingHx = async () => {
    try {
      const res = await api.getUserTrackingHistory();
      if(res.status == 200){
        setUserTrackingHx([
          {data: res.data.tracking_data},
        ]);
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }catch(error) {
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    getUserTrackingHx();
  }, []);


  console.log("IN TRACKING COMPO TRD", userTrackingHx);
  return (
    <>
      <Layout>
        <div className="history-wrapper w-full mb-10">
          <div className="main-wrapper w-full">
            <TrackCategory trackcategory={props.trackCategory} className="mb-10" />
            <TrackingTable userTrackingHx={userTrackingHx}/>
          </div>
        </div>
      </Layout>
    </>
  );
}
