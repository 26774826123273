import React, { useState } from "react";
import SellAnalysChart from "../Charts/SellAnalysChart";
import SelectBox from "../Helpers/SelectBox";

export default function PricingListTable(props) {

 // debugger;
 
  
  return (
    <div className="sell-analise w-full md:p-8 p-4 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow mb-11">
      <div className="flex flex-col justify-between h-full">
        <div className="content flex justify-between items-center mb-5">
        <ul>
                 {
        props.priceDetail.features.map((item,index)=>(
          <div>
            <li className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
               {item}
            </li>
           
            </div>
        ))
       }
       </ul>
        </div>
        <div>

        </div>
      </div>
    </div>
  );
}
