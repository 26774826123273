import React, { useEffect, useState } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import usersService from "../../services/UsersService";
import SelectBox from "../Helpers/SelectBox";
import { Link } from "react-router-dom";
import localImgLoad from "../../lib/localImgLoad";

export default function ReminderTable({ className }) {
  const api = new usersService();

  const [isLoading, setIsLoading] = useState(true)

  const [userReminders, setUserReminders] = useState([]);

  
  const getUserReminders = async () => {
    try {
      const res = await api.getUserReminders();
      if(res.status == 200){
        setUserReminders([
          {reminders: res.data.reminders},
          {category: [{category: 'All category', code: 'All category'}, ...res.data.reminder_category]}
        ]);
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }catch(error) {
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    getUserReminders();
  }, []);

  const [selectedCategory, setCategory] = useState('All category')
  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px] ${
        className || ""
      }`}
    >
      <div className="header w-full flex justify-between items-center mb-5">
        <div className="flex space-x-2 items-center">
          <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
            Reminders List
          </h1>
        </div>
        <SelectBox
          action={setCategory}
          datas={userReminders.length > 0 && userReminders[1].category}
          className="Update-table-dropdown"
          contentBodyClasses="w-auto min-w-max"
        />
      </div>
      {
        userReminders.length < 1 ?
        // when no reminders display below code
        <div  className="lg:flex justify-center">
          <div style={{ transform: 'translateY(150px)' }}>
            {
              isLoading ? 
              <div role="status">
                <svg aria-hidden="true" className="w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>
              :
            <Link
              to="/add-reminder"
              className="btn-gradient lg:flex hidden w-[300px] h-[50px] rounded-full text-white justify-center items-center"
            >
              0 Reminders Start Reminder
            </Link>
            }
          </div>
        </div>
        :
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <tbody>
            <>
              <tr className="text-base text-thin-light-gray whitespace-nowrap border-b dark:border-[#5356fb29]  default-border-bottom">
                <td className="py-4">Item</td>
                <td className="py-4 text-center">Start</td>
                <td className="py-4 text-center">End</td>
                <td className="py-4 text-center">Reminder</td>
                <td className="py-4 text-right">.</td>
              </tr>
              <tr className="spacer"></tr>
            </>
          
                {
                  userReminders[0].reminders.length > 0 && (
                    selectedCategory == "All category" ? (
                      userReminders[0].reminders.map((reminder, index) => (
                      <>
                        {/* <tr key={index} className="bg-white dark:bg-dark-white border border-light-purple dark:border-[#5356fb29]  hover:bg-gray-50"> */}
                        <tr key={index} className="bg-light-purple dark:bg-black hover:bg-gray-50 transition-all duration-500">
                            <td className="py-4 px-2 rounded-l-full">
                              <div className="flex space-x-2 items-center">
                                <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                                  <img
                                    src={(reminder.category == null || reminder.category == '')? dataImage1: localImgLoad(`images/${reminder.category}.png`)}
                                    alt="data"
                                    className="w-full h-full"
                                  />
                                </div>
                                <div className="flex flex-col">
                                  <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                                  {reminder.description.substring(0, 30)+ ' ...'}
                                  </h1>
                                  <span className="text-sm text-thin-light-gray dark:text-white"> 
                                  
                                    Added <span className="text-purple dark:text-white">
                                      {/* {new Date(reminder.added).toLocaleString().replace(/-/g, '/')} */}
                                      {reminder.added.split(' ')[0]}
                                      </span>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-center py-4 px-2">
                              <div className="flex space-x-1 items-center justify-center">
                    
                                <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                                {/* {new Date(reminder.start_date).toLocaleString().replace(/-/g, '/')} */}
                                {reminder.start_date.split(' ')[0]}
                                </span>
                              </div>
                            </td>
                            <td className="text-center py-4 px-2">
                              <div className="flex space-x-1 items-center justify-center">
                                <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                                {/* {new Date(reminder.end_date).toLocaleString().replace(/-/g, '/')} */}
                                {reminder.end_date.split(' ')[0]}
                                </span>
                              </div>
                            </td>
                            <td className="text-right py-4 px-2">
                              <span className="text-base text-thin-light-gray whitespace-nowrap">
                              {reminder.reminder}
                              </span>
                            </td>
                            <td className="text-right py-4 px-2 rounded-r-full">
                            <Link to={`/add-reminder/${reminder.uuid}`} state={{reminder}} className="text-sm text-white btn-gradient px-2.5 py-1.5 rounded-full">Edit</Link>
                            </td>
                        </tr>
                        <tr className="spacer"></tr>
                      </>
                      ))
                    )
                    :
                    userReminders[0].reminders.map((reminder, index) => {
                      if(reminder.category == selectedCategory){
                        return (
                          <>
                          {/* <tr key={index} className="bg-white dark:bg-dark-white border border-light-purple dark:border-[#5356fb29]  hover:bg-gray-50"> */}
                          <tr key={index} className="bg-light-purple dark:bg-black hover:bg-gray-50 transition-all duration-500">
                            <td className="py-4 px-2 rounded-l-full">
                              <div className="flex space-x-2 items-center">
                                <div className="w-[60px] h-[60px] rounded-full overflow-hidden flex justify-center items-center">
                                <img
                                    src={(reminder.category == null || reminder.category == '')? dataImage1: localImgLoad(`images/${reminder.category}.png`)}
                                    alt="data"
                                    className="w-full h-full"
                                  />
                                </div>
                                <div className="flex flex-col">
                                  <h1 className="font-bold text-xl text-dark-gray dark:text-white whitespace-nowrap">
                                  {reminder.description.substring(0, 30)+ ' ...'}
                                  </h1>
                                  <span className="text-sm text-thin-light-gray dark:text-white">
                                  
                                    Added <span className="text-purple dark:text-white">
                                    {new Date(reminder.added).toLocaleString().replace(/-/g, '/')}
                                      </span>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="text-center py-4 px-2">
                              <div className="flex space-x-1 items-center justify-center">
                    
                                <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                                {new Date(reminder.start_date).toLocaleString().replace(/-/g, '/')}
                                </span>
                              </div>
                            </td>
                            <td className="text-center py-4 px-2">
                              <div className="flex space-x-1 items-center justify-center">
                                <span className="text-base text-dark-gray dark:text-white font-medium whitespace-nowrap">
                                {new Date(reminder.end_date).toLocaleString().replace(/-/g, '/')}
                                </span>
                              </div>
                            </td>
                            <td className="text-right py-4 px-2">
                              <span className="text-base text-thin-light-gray whitespace-nowrap">
                              {reminder.reminder}
                              </span>
                            </td>
                            <td className="text-right py-4 px-2 rounded-r-full">
                              {/* <button
                                type="button"
                                className="text-sm text-white bg-purple px-2.5 py-1.5 rounded-full"
                              >
                                Edit
                              </button> */}
                              <Link to={`/add-reminder/${reminder.uuid}`} state={{reminder}} className="text-sm text-white btn-gradient px-2.5 py-1.5 rounded-full">Edit</Link>
                            </td>
                          </tr>
                          <tr className="spacer"></tr>
                          </>
                        )
                      }
                    })
                  )
                }
          </tbody>
        </table>
      </div>
      }
    </div>
  )
}