import React,{useState} from "react";
import {useNavigate} from "react-router-dom"
import titleShape from "../../../assets/images/shape/text-shape-three.svg";
import AuthLayout from "../AuthLayout";
import Otp from "./Otp";
import usersService from "../../../services/UsersService";

export default function VerifyYou() {
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState("");
  const verifyOTP = new usersService()
  const navigate = useNavigate()

  const [verificationCode, setVerificationCode] = useState({
    value_one: '',
    value_two: ''
  })

  const handleVerificationInput = ({target:{name, value}}) => { 
    setVerificationCode(prev => {
      return {...prev, [name]:value}
    })
  }

  const handleSubmit = async() => {
    setValidation("")
    setLoading(true)
    
    let otpCode = '';
    for(let values in verificationCode){
      otpCode+=verificationCode[values]
    }
    
    // Validating otp code
    if(!otpCode) {
      setLoading(false)
      setValidation("Please enter your otp code")
      return
    }
    if(otpCode.length < 6) {
      setLoading(false)
      setValidation("OTP code incomplete")
      return
    }
    
    const {username} = JSON.parse(localStorage.getItem('reset_raw'))
    const verifyEmail = {
      username: username,
      stage: 200,
      reset_uuid: localStorage.getItem('reset_uuid'),
      random_text: otpCode
    }

    localStorage.setItem('otp', otpCode)

    try {
    const verify = await verifyOTP.resetPassword(verifyEmail);
    console.log(verify)
    localStorage.setItem('member_uid', verify.data.member_uid);
    if (verify.status != 200){
      setValidation("Sorry, could not verify code")
      setLoading(false)
      return
    }
      
    if (verify.status == 200){

      if(verify?.data.error_msg == "Unable to continue"){
        setLoading(false)
        setValidation("Incorrect otp code")
        return
      }

      setValidation("verified successfully")

      setTimeout(() => {
        setLoading(false)
        navigate("/update-password", {replace : true});
    }, 2000);
        return
      }
    } catch (error) {
      setLoading(false)
      setValidation('An error occurred')
    }
  }
  
  return (
    <>
      <AuthLayout
        slogan="Welcome to myFit"
      >
        <div className="content-wrapper xl:bg-white dark:bg-dark-white   w-full sm:w-auto px-5 xl:px-[70px] 2xl:px-[100px] h-[818px] rounded-xl flex flex-col justify-center">
          <div>
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-8">
              <h1 className="sm:text-5xl text-4xl font-bold leading-[74px]   text-dark-gray dark:text-white">
                Verification Code
              </h1>
              <div className="shape sm:w-[377px] w-[270px] -mt-5 ml-5">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <Otp handleChange={handleVerificationInput} value={verificationCode} />
              {validation && <p className={`my-5 text-center font-light italic text-sm subpixel-antialiased tracking-wide ${validation == 'verified successfully' ? 'text-green-600' : 'text-red-500'} `}>{validation}</p>}
              <div className="signin-area mb-3.5">
                <a
                  // href="/update-password"
                  className="w-full rounded-[50px] h-[58px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center"
                  onClick={handleSubmit}
                >
                  {loading ? <div className="signup btn-loader"></div> : <span>Continue</span>}
                </a>
              </div>
              <div className="resend-code flex justify-center">
                <p className="text-lg text-thin-light-gray font-normal">
                  Dont’t have an aceount ?
                  <a href="/signup" className="ml-2 text-dark-gray dark:text-white font-bold">
                    Sign Up
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
