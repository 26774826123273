import React, { useState } from "react";
import loginThumb from "../../../assets/images/auth-thumb.svg";
import googleLogo from "../../../assets/images/google-logo.svg";
import logo from "../../../assets/images/light-logo.png"; //logo-1.svg";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import { Link, useNavigate } from 'react-router-dom';

import usersService from "../../../services/UsersService"; // site api services

export default function SignUp() {
  const navigate = useNavigate();

  const userSignup = new usersService();

  const [loading, setLoading] = useState(false) // For loading spinner

  const [errorMessage, setErrorMessage] = useState({ // For Displaying success or error message
    success: false,
    message: ''
  })

  const [checked, setValue] = useState(false);
  const rememberMe = () => {
    setValue(!checked);
  };

  // state for user inputed values
  const [userDetails, setUserDetails] = useState({
    // username: '',
    email: '',
    password: '',
    confirm_password: '',
    firstname: '',
    lastname: ''
  })

  const handleInputChange = ({target:{name, value}}) => { // function that listens to input change
    setUserDetails(prev => {
      return {...prev, [name]:value}
    })
  }

  const handleUserSignup = async () => { // handles input validation and submits to api call

    setErrorMessage({ // resets the error message to empty string
      success: false,
      message: ''
    }) 
    
    setLoading(true) // Sets loading spinner

    let userInfo = {...userDetails} // assigns userDetails to be user information

    let {email, password, confirm_password, firstname, lastname} = userInfo

    if(!email || !password || !confirm_password || !firstname || !lastname){ // checks if any field is empty
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Please Fill all inputs'
      })
      return
    }
    //checks if email is a valid email address
    let regEx = /^[^0-9][a-zA-Z0-9._%+-]+@[a-zA-Z]+(\.[a-zA-Z]+)+$/;
    if (regEx.test(email) == false) {
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Please Input a valid email; e.g: text@gmail.com'
      })
      return
    }

    if(password != confirm_password){ //checks if password matches confirm password
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Password do not match'
      })
      return
    }

    //checks if password is matches alphanumeric with at least one uppercase letter
    // let PwdRegEx = /[A-Z]/;
    if (/[A-Z]/.test(password) == false) {
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Password must contain at least one uppercase character; e.g: Text123'
      })
      return
    }

    userInfo.username = email // assigns email as username also
    userInfo.mode = 'START' // assigns mode as START

    delete userInfo.confirm_password // deletes confrim password before making API call

    try {
      const res = await userSignup.signupUser(userInfo);
      if(res.status != 200){
        setLoading(false)
        setErrorMessage({
          success: false,
          message: 'Could not create account try again later'
        })
        return
      }

      if(res.status == 200){
        if(res.data.status < 0) { // when resquest is successful but status is not 1
          setLoading(false)
          setErrorMessage({
            success: false,
            message: 'unable to create account'
          })
          return
        }

        // if request is successful and status is 1 proceed
        setErrorMessage({
          success: true,
          message: 'Account created successfully'
        })
  
        localStorage.setItem('uuid', res.data.uuid) // Stores the user UUID to localstorage
        localStorage.setItem('username', email) // Stores the user UUID to localstorage
  
        setTimeout(()=>{
          setLoading(false)
          navigate("/verify-signup", { replace: true })
        }, 1000)
      }
    } catch (error) {
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Opps! something went wrong, Try agian later'
      })
    }
  }

  return (
    <>
      <div className="layout-wrapper">
        <div className="main-wrapper w-full xl:h-screen h-full xl:py-0 py-12">
          <div className="flex w-full h-full">
            <div className="xl:flex hidden w-1/2 h-full p-[70px]  flex-col justify-between primary-home">
              {/*
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
              */}
              <div className="thumbnail flex justify-center">
                {/*<img src={loginThumb} alt="login-thumb" /> */}
              </div>
              <div className="article w-[600px]">
                <p className="text-[60px] font-bold leading-[72px] text-white">
                  Welcome to myFit
                </p>
              </div>
            </div>
            <div className="flex-1 flex justify-center items-center">
              <div className="content-wrapper xl:bg-white dark:bg-dark-white   xl:px-7 px-5 2xl:px-[100px] h-[840px] rounded-xl flex flex-col justify-center">
                <div>
                  <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                    <h1 className="sm:text-5xl text-4xl font-bold  text-dark-gray dark:text-white leading-2">
                      Create Account
                    </h1>
                    <div className="shape sm:w-[377px] w-[280px] mb-[10px] ml-5">
                      <img src={titleShape} alt="shape" />
                    </div>
                  </div>
                  <div className="input-area">
                    <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
                      <div className="input-item sm:w-1/2 w-full mb-5 sm:mb-0">
                        <InputCom
                          placeholder="Adam"
                          label="First Name"
                          name="firstname"
                          type="text"
                          iconName="people"
                          value={userDetails.first_name}
                          inputHandler={handleInputChange}
                        />
                      </div>
                      <div className="input-item flex-1">
                        <InputCom
                          placeholder="Wathon"
                          label="Last Name"
                          name="lastname"
                          type="text"
                          iconName="people"
                          value={userDetails.Last_name}
                          inputHandler={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="input-item mb-5">
                      <InputCom
                        placeholder="example@quomodosoft.com"
                        label="Email Address"
                        name="email"
                        type="email"
                        iconName="message"
                        value={userDetails.email}
                        inputHandler={handleInputChange}
                      />
                    </div>
                    <div className="input-item mb-5">
                      <InputCom
                        placeholder="*********"
                        label="Password"
                        name="password"
                        type="password"
                        iconName="password"
                        value={userDetails.password}
                        inputHandler={handleInputChange}
                      />
                    </div>
                    <div className="input-item mb-5">
                      <InputCom
                        placeholder="*********"
                        label="Re-enter Password"
                        name="confirm_password"
                        type="password"
                        iconName="password"
                        value={userDetails.confirm_password}
                        inputHandler={handleInputChange}
                      />
                    </div>
                    <div className="forgot-password-area flex justify-between items-center mb-6">
                      <div className="remember-checkbox flex items-center space-x-2.5">
                        <button
                          onClick={rememberMe}
                          type="button"
                          className="w-5 h-5 text-dark-gray dark:text-white flex justify-center items-center border border-light-gray"
                        >
                          {checked && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          )}
                        </button>
                        <span
                          onClick={rememberMe}
                          className="text-base   text-dark-gray dark:text-white"
                        >
                          I agree all
                          <a
                            href="#"
                            className="text-base text-purple mx-1 inline-block"
                          >
                            terms and condition
                          </a>
                          in myFit.
                        </span>
                      </div>
                    </div>
                    <div className="signin-area mb-1">

                    {errorMessage.message != '' &&  <p className={`text-center p-3 ${errorMessage.success ? 'text-green-600' : 'text-red-600'}`}>{errorMessage.message}</p>}

                      <button
                  className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                  // onClick={() => navigate("/verify-signup")}
                  onClick={handleUserSignup}
                >
                  {loading && (
                  <div role="status" className="px-3">
                      <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      <span className="sr-only">Loading...</span>
                  </div>
                  )}
                  Sign Up
                </button>
                      {/* <a
                        href="#" onClick={() => navigate("/verify-you")}
                        className="w-full border border-light-purple dark:border-[#5356fb29]  rounded-[50px] h-[58px] flex justify-center bg-[#FAFAFA] dark:bg-[#11131F]  items-center"
                      >
                        <img
                          className="mr-3"
                          src={googleLogo}
                          alt="google logo"
                        />
                        <span className="text-lg text-thin-light-gray font-normal">
                          Sign Up with Google
                        </span>
                      </a> */}
                    </div>
                    <div className="signup-area flex justify-center">
                      <p className="text-lg text-thin-light-gray font-normal">
                        Already have account?
                        <a href="/login" className="ml-2   text-dark-gray dark:text-white">
                          Log In
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
