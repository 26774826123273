import React, { useState, useEffect } from "react";
import dataImage1 from "../../assets/images/data-table-user-1.png";
import dataImage2 from "../../assets/images/data-table-user-2.png";
import dataImage3 from "../../assets/images/data-table-user-3.png";
import dataImage4 from "../../assets/images/data-table-user-4.png";
import SelectBox from "../Helpers/SelectBox";

import usersService from "../../services/UsersService"; // API SERVICE
import localImgLoad from "../../lib/localImgLoad"; // img loader


import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function CalendarTable({ className }) {
  const [isLoading, setIsLoading] = useState(true) // for determining when a page is awating request call

  const filterCategories = ["All Categories", "Explore", "Featured"];
  const [selectedCategory, setCategory] = useState(filterCategories[0]);

  const [value, onChange] = useState(new Date()); // value of calendar date and onchange handler for calendar
  const [remDate, setRemDate] = useState([]) // for holding dates on reminders to display on calendar

  const calenderApi = new usersService(); // instantiating the API SERVICE

  const getUserReminders = async () => {
    try {
      const res = await calenderApi.getUserReminders();
      if(res.status == 200 && res.data.status > 0){
        setRemDate(res.data.reminders)
        setIsLoading(false)
        return
      }
      setIsLoading(false)
    }catch(error) {
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    getUserReminders();
  }, []);

  return (
    <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white overflow-hidden rounded-2xl section-shadow min-h-[800px] ${
        className || ""
      }`}
    >
      <div className="header w-full flex justify-between items-center mb-5">
        <div className="flex space-x-2 items-center">
          <h1 className="text-xl font-bold text-dark-gray dark:text-white tracking-wide">
            Calendar
          </h1>
        </div>
        <SelectBox
          action={setCategory}
          datas={filterCategories}
          className="Update-table-dropdown"
          contentBodyClasses="w-auto min-w-max"
        />
      </div>


      <div>

        {
          isLoading ?  
            <div role="status" className="flex justify-center">
              <svg aria-hidden="true" className="w-32 h-32 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          :
          <Calendar 
            onChange={onChange} 
            value={value} 
            calendarType="US" 
           
            tileContent={({ date, view }) => {
              const reminder = []
              remDate.map((x) => {
                // console.log(new Date(date).toDateString() == new Date(x.start_date).toDateString())
                if(date.toDateString() == new Date(x.start_date.split(' ')[0]).toDateString()){
                  if(reminder.length < 1) {
                    reminder.push([x])
                  }else{
                    for(let i=0; i<reminder.length; i++){
                      if(reminder[i][0].start_date.split(' ')[0] == x.start_date.split(' ')[0]){
                      reminder[i].push(x)
                      break
                      }else if(reminder[i][0].start_date != x.start_date && i==reminder.length-1){
                        reminder.push([x])
                        break
                      }
                    }
                  }
                }
              });
              return reminder ? reminder.map((rem, index) => {
                if(rem){
                  return <ReminderThumbnail key={index} reminders={rem} />
                }
              }) : "";
            }}

          />
        }
      
    </div>



    </div>
  );
}

/**
 * Renders a list of reminder thumbnails.
 * Each thumbnail displays the reminder's category image, description, and start date.
 
 * Each object should have the following properties:
 * - category (string): The category of the reminder.
 * - description (string): The description of the reminder.
 * - start_date (string): The start date of the reminder.
 */
const ReminderThumbnail = ({ reminders }) => {
  const getThumbnailImage = (category) => {
    if (!category) {
      return dataImage1;
    }
    return localImgLoad(`images/${category}.png`);
  };

  return (
    <div className="w-full h-full overflow-y-auto overflow-x-hidden pb-1">
      {reminders.map((reminder, index) => (
        <div key={index} className="flex space-x-2 items-center bg-slate-300 hover:bg-white mb-1 p-1 transition-all duration-500">
          <div className="w-[30px] h-[30px] rounded-full overflow-hidden flex justify-center items-center">
            <img
              src={getThumbnailImage(reminder.category)}
              alt="data"
              className="w-full h-full"
            />
          </div>
          <div className="flex flex-col justify-start items-start">
            <h1 className="font-bold text-[10px] text-dark-gray dark:text-white whitespace-nowrap">
              {reminder.description?.substring(0, 20) + ' ...'}
            </h1>
            <span className="text-[8px] text-thin-light-gray">
              Added <span className="text-purple">
                {reminder.start_date.split(' ')[0]}
              </span>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};