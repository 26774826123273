import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import authProfilePic from "../../assets/images/auth-profile-picture.png";
import profileBanner from "../../assets/images/profile-cover.png";
import collections from "../../data/collectionplan_data.json";
import marketPlace from "../../data/marketplace_data.json";
import products from "../../data/product_data.json";
import Layout from "../Partials/Layout";
import ActivitiesTab from "./ActivitiesTab";
import CollectionTab from "./CollectionTab";
import CreatedTab from "./CreatedTab";
import HiddenProductsTab from "./HiddenProductsTab";
import OnSaleTab from "./OnSaleTab";
import OwnTab from "./OwnTab";

import BlogTab from "./BlogTab";
import VideoYouTube from "./VideoYouTube";

import usersService from "../../services/UsersService";

export default function Resources() {
  const onSaleProducts = marketPlace.data;
  const CreatedSell = marketPlace.data;
  const CreatedBits = products.datas;
  // const mainProducts = products.datas;
  const ownProducts = products.datas;
  const collectionProducts = collections.data;


  const api = new usersService();
  const [tabs, setUserTab] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [userResources, setUserResource] = useState([]);
  const [tab, setTab] = useState(/*tabs[0].name*/ null);
  
  const getUserResources = async () => {
    try {
      const res = await api.getUserResources();
      setUserResource(res.data.resources);
      setUserTab(res.data.category);
      setTab(res.data.category[0].name);
      setIsLoading(false)
      
    }catch(error) {
      setIsLoading(false)
    }
  };
  
  useEffect(() => {
    getUserResources();
  }, []);

  //debugger;

  const tabHandler = (value) => {
    setTab(value);
  };
  return (
    <>
      <Layout>
        <div className="nft-authprofile-wrapper w-full">
          <div className="main-wrapper w-full">
            <div className="content-wrapper-profile-only w-full mb-6">
              <div className="auth-tab-content relative mb-10">
                <div className="lg:flex justify-between">
                  <div className="tab-items">
                    <ul className="lg:flex lg:space-x-14 space-x-8">
                      {tabs &&
                        tabs.length > 0 &&
                        tabs.map((tabValue) => (
                          <li
                            key={tabValue.id}
                            className="relative group inline"
                            onClick={() => tabHandler(tabValue.name)}
                          >
                            <span
                              className={`py-4 sm:border-b-none border-b group-hover:border-purple border-transparent lg:text-xl text-sm tracking-wide font-bold  group-hover:text-purple text-dark-gray dark:text-white relative z-10 cursor-pointer ${
                                tab === tabValue.name
                                  ? "text-purple border-purple "
                                  : "text-dark-gray dark:text-white border-transparent "
                              }`}
                            >
                              {tabValue.content}
                            </span>
                            <span
                              className={`w-5 h-5 group-hover:bg-pink group-hover:text-white  text-[10px]  rounded-full absolute -top-2 -right-5 flex justify-center items-center ${
                                tab === tabValue.name
                                  ? "text-white bg-pink"
                                  : "text-thin-light-gray bg-[#F2B8FD]"
                              }`}
                            >
                              16
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div style={{ transform: "translateY(-22px)" }}>
           
                  </div>
                </div>
                <div className="hidden lg:block w-full h-[1px] bg-[#DCD5FE] dark:bg-[#5356fb29] absolute top-[42px] left-0"></div>
              </div>
            </div>

            <div className="tab-cotainer w-full mb-10">
              {tab === "blog" ? (
                <BlogTab products={onSaleProducts} resources={userResources} />
              ) : tab === "youtube" ? (
                <VideoYouTube products={onSaleProducts} resources={userResources} />
              ) : tab === "owned" ? (
                <OwnTab products={ownProducts} />
              ) : tab === "created" ? (
                <CreatedTab
                  marketProducts={CreatedSell}
                  mainProducts={CreatedBits}
                />
              ) : tab === "hidden" ? (
                <HiddenProductsTab
                  marketProducts={CreatedSell}
                  mainProducts={CreatedBits}
                />
              ) : tab === "collection" ? (
                <CollectionTab products={collectionProducts} />
              ) : tab === "activity" ? (
                <ActivitiesTab />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
