import React from "react";
import AddEditReminder from "../components/Reminders/AddEditReminder";

export default function RemindersAddPage() {
  return (
    <>
      <AddEditReminder />
    </>
  );
}
