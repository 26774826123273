import React from "react";
import loginThumb from "../../assets/images/auth-thumb.svg";
import logo from "../../assets/images/light-logo.png";

/**
 * Renders a login layout with a slogan and child components.
 * The layout adjusts its height based on the screen height.
 * @returns {React.Component} The rendered login layout.
 */
export default function LoginLayout({ slogan, children }) {
  const screen = window.screen.height <= 950 ? "h-screen" : "h-screen";

  return (
    <div className="layout-wrapper">
      <div className={`main-wrapper w-full ${screen}`}>
        <div className="flex w-full h-full">
          <div className="xl:flex hidden w-1/2 h-full p-[70px]  flex-col justify-between primary-home">
            {/* <div className="logo">
              <img src={logo} alt="logo" />
            </div> */}
            <div className="thumbnail flex justify-center">
              {/* <img src={loginThumb} alt="login-thumb" /> */}
            </div>
            <div className="article w-[600px]">
              <p className="text-[60px] font-bold leading-[72px] text-[#a03774]">
                {slogan} 
              </p>
            </div>
          </div>
          <div className="flex-1 flex justify-center items-center">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}