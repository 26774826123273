import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icons from "../Helpers/Icons";


export default function SideMenuSub() {
  return (
    <>
        <div className="top-platform bg-white dark:bg-dark-white   rounded-2xl p-8 2xl:w-[268px] w-full 2xl:mb-10 2xl:border-none  border ">
          {/* heading */}
          <div className="heading flex justify-between items-center mb-3.5">
            <h3 className="text-xl font-bold   text-dark-gray dark:text-white">
              Subscription
            </h3>
            <div>
            </div>
          </div>

          <div className="platform-list flex flex-col gap-4">
          
            <div className="item flex space-x-3 items-center"> 
               <p className="text-thin-light-gray text-base font-medium">
                 Show Sub level
              </p>
              {/* action */}
            </div>
           
            <Link to="/subscription" className="cursor-pointer p-2 md:hover:shadow-md transition md:hover:duration-300 rounded-md">       
            <div className="item flex space-x-3 items-center">
              <span className="item-icon group-hover:bg-purple group-hover:text-white w-8 h-8 flex justify-center items-center transition-all duration-300 ease-in-out  bg-light-purple dark:bg-dark-light-purple rounded-full">
                <Icons name="tracking2" />
              </span>
                <p className="text-thin-light-gray text-base font-medium">
                  Upgrade
                </p>
              {/* action */}
            </div>
            </Link>
          </div>
        </div>
    </>
  );
}
