import React, {useState} from "react";
import titleShape from "../../../../assets/images/shape/title_shape_3.svg";
import AuthLayout from "../../AuthLayout";
import Otp from "./Otp";
import { useNavigate } from 'react-router-dom';
import usersService from "../../../../services/UsersService";

export default function VerifyYou() {

  const navigate = useNavigate();

  const verifyOTP = new usersService();

  const [loading, setLoading] = useState(false) // For loading spinner

  const [errorMessage, setErrorMessage] = useState({ // For Displaying success or error message
    success: false,
    message: ''
  })


  // state for user inputed values
  const [verificationCode, setVerificationCode] = useState({
    value_one: '',
    value_two: '',
    value_three: '',
    value_four: '',
    value_five: '',
    value_six: '',
  })

  const handleVerificationInput = ({target:{name, value}}) => { // function that listens to input change
    setVerificationCode(prev => {
      return {...prev, [name]:value}
    })
  }

  const handleUserOTPVerify = async () => { // handles input validation and submits to api call

    setErrorMessage({ // resets the error message to empty string
      success: false,
      message: ''
    }) 
    
    setLoading(true) // Sets loading spinner

    let otpCode = '';
    for(let values in verificationCode){
      otpCode+=verificationCode[values]
    }

    if(!otpCode){ // checks if code is empty
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Please input the code sent to you'
      })
      return
    }
    if(otpCode.length < 6){ // checks if verifiedCode is empty
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Code must be 6 characters'
      })
      return
    }

    let apiInput = {
      username: localStorage.getItem('username'),
      pend_uid: localStorage.getItem('uuid'),
      random_text: otpCode,
      mode: 'VERIFY',
    }

    try {
      const res = await verifyOTP.signupUser(apiInput)
      if(res.status != 200){
        setLoading(false)
        setErrorMessage({
          success: false,
          message: 'Could not verify code'
        })
        return
      }

      if(res.status == 200){
        if(res.data.status < 0) { // when resquest is successful but status is not 100
          setLoading(false)
          setErrorMessage({
            success: false,
            message: res.data.error_msg
          })
          return
        }

        // if request is successful and status is 100 proceed
        setErrorMessage({
          success: true,
          message: 'verification successfully'
        })

        //clears the temporary uuid and email in tge local storage
        localStorage.removeItem('uuid')
        localStorage.removeItem('username')

        setTimeout(()=>{
          setLoading(false)
          navigate('/complete-signup', { replace: true })
        }, 1000)
      }
      
    } catch (error) {
      setLoading(false)
      setErrorMessage({
        success: false,
        message: 'Opps! something went wrong, Try agian later'
      })
    }
  }

  return (
    <>
      <AuthLayout
        slogan="Welcome to myFit"
      >
        <div className="content-wrapper xl:bg-white dark:bg-dark-white   w-full sm:w-auto px-5 xl:px-[70px] 2xl:px-[100px] h-[818px] rounded-xl flex flex-col justify-center">
          <div>
            <div className="title-area flex flex-col justify-center items-center relative text-center mb-8">
              <h1 className="mb-4 sm:text-5xl text-4xl font-bold leading-[74px]   text-dark-gray dark:text-white">
               SignUp Verification 
              </h1>
              <div className="shape">
                <img src={titleShape} alt="shape" />
              </div>
            </div>
            <div className="input-area">
              <Otp handleChange={handleVerificationInput} value={verificationCode}/>
              {errorMessage.message != '' &&  <p className={`text-center p-3 ${errorMessage.success ? 'text-green-600' : 'text-red-600'}`}>{errorMessage.message}</p>}
              <div className="signin-area mb-3.5">
                <button
                  // href="/update-password"
                  onClick={handleUserOTPVerify}
                  className="w-full rounded-[50px] h-[58px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center"
                >
                  {loading && (
                  <div role="status" className="px-3">
                      <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      <span className="sr-only">Loading...</span>
                  </div>
                  )}
                  Continue
                </button>
              </div>
              <div className="resend-code flex justify-center">
                <p className="text-lg text-thin-light-gray font-normal">
                  Dont’t have an aceount ?
                  <a href="#" className="ml-2 text-dark-gray dark:text-white font-bold">
                    Please resend
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}
