import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import titleShape from "../../../assets/images/shape/title-shape-two.svg";
import InputCom from "../../Helpers/Inputs/InputCom";
import AuthLayout from "../AuthLayout";
import ThankYou from "../ThankYou";
import usersService from "../../../services/UsersService";

export default function UpdatePassword() {
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  })
  const [message, setMessage] = useState(false);
  const [validation, setValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const updatePass = new usersService()
  const navigate = useNavigate()
  
  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const updatePassword = async (e) => {
    const {username} = JSON.parse(localStorage.getItem('reset_raw'))
    const otpCode = localStorage.getItem('otp')

    setLoading(true)

    if(!values.password || !values.confirmPassword){
      setLoading(false)
      setValidation("Please Fill empty inputs")
      return
    }

    const regex = /^[A-Za-z]\w{7,14}$/
    if(regex.test(values.password) == false) {
      setLoading(false)
      setValidation("it must be a least 7 alphanumeric characters")
      return
    }

    if (values.password != values.confirmPassword){
      setLoading(false)
      setValidation("Password does not match")
      return
    }

    const newPassword = {
      username: username,
      reset_uuid: localStorage.getItem('reset_uuid'),
      random_text: otpCode,
      member_uid: localStorage.getItem('member_uid'),
      new_password: values.password,
      stage: 300
    }

    delete values.confirmPassword

    try {
      const confirm = await updatePass.resetPassword(newPassword)
      console.log(confirm)

      if(confirm.status != 200){
      setLoading(false)  
      setValidation("Sorry, could not verify code")
      return
      }
      
      localStorage.removeItem('reset_uuid')
      localStorage.removeItem('reset_raw')
      localStorage.removeItem('otp')

      if(confirm.status == 200){
        setValidation("Password updated")
        setTimeout(() => {
          setLoading(false)
          navigate("/confirm-reset", {replace : true});
      }, 2000);
      }
    } catch (error) {
      setLoading(false)
      setValidation("An error occurred")
    }
  };

  return (
    <>
      <AuthLayout
        slogan="Welcome to  myFit"
      >
          <div className="content-wrapper update-password-section xl:bg-white dark:bg-dark-white   w-full 2xl:h-[818px] xl:h-[600px] sm:w-auto sm:px-[70px] px-5 2xl:px-[100px] rounded-xl flex flex-col justify-center">
            <div>
              <div className="title-area relative flex flex-col justify-center items-center mb-7">
                <h1 className="sm:text-5xl text-4xl font-bold leading-[74px]   text-dark-gray dark:text-white">
                  Update Password
                </h1>
                {/* w-[341px] absolute top-14 left-12 */}
                <div className="shape sm:w-[341px] w-[270px] -mt-5 sm:-mt-1 ml-5">
                  <img src={titleShape} alt="shape" />
                </div>
              </div>
              <div className="input-area">
                {/* <div className="input-item mb-5">
                  <InputCom
                    placeholder="*********"
                    label="Old Password"
                    name="password"
                    type="password"
                    iconName="password"
                  />
                </div> */}
                <div className="input-item mb-5">
                  <InputCom
                    placeholder="*********"
                    label="New Password"
                    name="password"
                    type="password"
                    iconName="password"
                    inputHandler={onChange}
                  />
                </div>
                <div className="input-item mb-5">
                  <InputCom
                    placeholder="*********"
                    label="Re-enter Password"
                    name="confirmPassword"
                    type="password"
                    iconName="password"
                    inputHandler={onChange}
                  />
                </div>
                {validation && <p className={`my-5 text-center font-light italic text-sm subpixel-antialiased tracking-wide ${validation == 'Password updated' ? 'text-green-600' : 'text-red-500'} `}>{validation}</p>}
                <div className="signin-area mb-3.5">
                  <button
                    onClick={updatePassword}
                    type="button"
                    className="w-full rounded-[50px] mb-5 h-[58px] text-xl text-white font-bold flex justify-center bg-purple items-center"
                  >
                    {loading ? <div className="signup btn-loader"></div> : <span>Continue</span>}
                  </button>
                </div>
              </div>
            </div>
          </div> 
        {/* // : (
        //   <ThankYou className={`thankyou-section ${message ? "active" : ""}`} />
        // )} */}
      </AuthLayout>
    </>
  );
}
