import React from 'react'
import HistoryAnalyticsCard from '../Cards/HistoryAnalyticsCard'
import Layout from '../Partials/Layout'
import ReminderTable from './ReminderTable'
import { Link } from 'react-router-dom'

export default function Reminders() {
  return (
    <>
      <Layout>
        <div className="content-wrapper-profile-only w-full mb-6">
          <div className="auth-tab-content relative mb-10">
            <div className="lg:flex justify-between">
              <div className="tab-items"></div>
              <div style={{ transform: 'translateY(-22px)' }}>
                <Link
                  to="/add-reminder"
                  className="btn-gradient btn-shine lg:flex hidden w-[153px] h-[46px] rounded-full text-white justify-center items-center"
                >
                  Add Reminder
                </Link>
              </div>
            </div>
            <div className="hidden lg:block w-full h-[1px] bg-[#DCD5FE] dark:bg-[#5356fb29] absolute top-[42px] left-0"></div>
          </div>
        </div>
        <div className="history-wrapper w-full mb-10">
          <div className="main-wrapper w-full">
            <ReminderTable />
          </div>
        </div>
      </Layout>
    </>
  )
}
