import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: localStorage.getItem('profile') && JSON.parse(localStorage.getItem("profile"))
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state,payload) => {
      // eslint-disable-next-line no-param-reassign
      state.userInfo = payload.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserInfo } = userSlice.actions;

export default userSlice.reducer;