import React, { useEffect, useState } from "react";
import Tracking from "../components/Tracking";
import usersService
 from "../services/UsersService";
export default function TrackingPage() {
    const userApi = new usersService();
    const [trackCategory, setTrackCategory] = useState([]);
//debugger;
    const getTrackCategory = async () => {
        try {
          const res = await userApi.getTrackCategory(); 
          if(res.status == 200){
            setTrackCategory(res.data);
            return
          }
        }catch(error) {
            console.log("error ~");
        }
      };
      
      useEffect(() => {
        getTrackCategory();
      }, []);

    return (
        <>
            <Tracking 
              trackCategory={trackCategory}
            />
        </>
    );
}
