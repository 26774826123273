/* eslint-disable no-unused-expressions */

import React, { useState } from "react";
import Icons from "../../Helpers/Icons";
import PasswordSvg from "../PasswordSvg";
import { toast } from "react-toastify";
import usersService from "../../../services/UsersService";

export default function ChangePasswordTab() {
  const [oldPass, setOldPass] = useState("hide-password");
  const [newPass, setNewPass] = useState("hide-password");
  const [confirmPass, setConfirmPass] = useState("hide-password");
  const showPassword = (value) => {
    const password = document.getElementById(`${value}`);
    if (value && value === "old_password") {
      if (password.type === "password") {
        password.type = "text";
        setOldPass("show-password");
      } else {
        password.type = "password";
        setOldPass("hide-password");
      }
    }
    if (value && value === "new_password") {
      if (password.type === "password") {
        password.type = "text";
        setNewPass("show-password");
      } else {
        password.type = "password";
        setNewPass("hide-password");
      }
    }
    if (value && value === "confirm_password") {
      if (password.type === "password") {
        password.type = "text";
        setConfirmPass("show-password");
      } else {
        password.type = "password";
        setConfirmPass("hide-password");
      }
    }
  };

  // Reset Password
  const reset_profilePass = new usersService()
  const [loading, setLoading] = useState(false) 
  const [validation, setValidation] = useState("")
  // state for password values
  const [passwordDetails, setPasswordDetails] = useState({
    prev_pass: '',
    new_pass: '',
    confirm_pass: ''
  })
  // tracks password changes
  const handlePasswordChange = ({target:{name, value}}) => {
    setPasswordDetails(prev => ({...prev, [name]:value}))
  }

  const handleResetPassword = async(e) => {
    setValidation("")
    setLoading(true)

    let resetPassword = {...passwordDetails}
    let {prev_pass, new_pass, confirm_pass} = resetPassword

    // Check empty fields
    if(!prev_pass || !new_pass || !confirm_pass) {
      setLoading(false)
      setValidation("Please fill the empty fields")
      return
    }

    // Checking to see the password has 
    if(!/^[A-Za-z]\w{6,14}$/.test(new_pass)) {
      setLoading(false)
      setValidation("it must be a more 7 alphanumeric characters")
      return
    }

    // matching new passwords with confirm_password
    if(new_pass != confirm_pass){
      setLoading(false)
      setValidation("new password does not match")
      return
    }

    // matching new passwords with old passwords
    if(prev_pass === new_pass){
      setLoading(false)
      setValidation("you can't use old passwords")
      return
    }

    // assigning new values to resetPassword
    resetPassword.member_id = localStorage.getItem("member_id")
    resetPassword.session_token = localStorage.getItem("session_token")
    resetPassword.member_uuid = localStorage.getItem("member_uuid")

    // removed confirm password from the payload
    delete resetPassword.confirm_pass

    try {
      const res = await reset_profilePass.resetProfilePassword(resetPassword)
      console.log(res)
      
      if(res.status != 200){
        setLoading(false)
        setValidation("An error occurred")
        return
      }
      
      if(res.status == 200){
        // if status is okay but can't success not granted
        if(res.data.status < 0 ) {
          setLoading(false)
          setValidation("sorry, error updating password")
        } else {
          setLoading(false)
          // setValidation("password updated successfully")
          toast.success("password updated successfully")
  
          // setPasswordDetails(prev => ({...prev, e.target.name: ''}))
        }
      }
    } catch (error) {
      setLoading(false)
      setValidation("something went wrong, try again later")
    }

  }

  return (
    <div className="changePasswordTab w-full">
      <div className="w-full flex xl:flex-row flex-col-reverse space-x-5 xl:items-center">
        <div className="flex-1 mb-10">
          <div className="input-field mb-6">
            <label
              className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5"
              htmlFor="old_password"
            >
              Old Password
            </label>
            <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  w-full rounded-[50px] h-[58px] overflow-hidden relative ">
              <div className="absolute left-6 bottom-[17px] z-10">
                <Icons name="password" />
              </div>
              <input
                placeholder="● ● ● ● ● ●"
                className="input-field placeholder:text-base text-bese px-12 text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                type="password"
                id="old_password"
                name='prev_pass'
                value={passwordDetails.prev_pass}
                onChange={handlePasswordChange}
              />
              <div
                className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                onClick={() => showPassword("old_password")}
              >
                <Icons name={oldPass} />
              </div>
            </div>
          </div>
          <div className="input-field mb-6">
            <label
              className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5"
              htmlFor="new_password"
            >
              New Password
            </label>
            <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  w-full rounded-[50px] h-[58px] overflow-hidden relative ">
              <div className="absolute left-6 bottom-[17px] z-10">
                <Icons name="password" />
              </div>
              <input
                placeholder="● ● ● ● ● ●"
                className="input-field placeholder:text-base text-bese px-12 text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                type="password"
                id="new_password"
                name="new_pass"
                value={passwordDetails.new_pass}
                onChange={handlePasswordChange}
              />
              <div
                className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                onClick={() => showPassword("new_password")}
              >
                <Icons name={newPass} />
              </div>
            </div>
          </div>
          <div className="input-field mb-8">
            <label
              className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5"
              htmlFor="confirm_password"
            >
              Confirm Password
            </label>
            <div className="input-wrapper border border-light-purple dark:border-[#5356fb29]  w-full rounded-[50px] h-[58px] overflow-hidden relative ">
              <div className="absolute left-6 bottom-[17px] z-10">
                <Icons name="password" />
              </div>
              <input
                placeholder="● ● ● ● ● ●"
                className="input-field placeholder:text-base text-bese px-12 text-dark-gray dark:text-white w-full h-full bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none"
                type="password"
                id="confirm_password"
                name="confirm_pass"
                value={passwordDetails.confirm_pass}
                onChange={handlePasswordChange}
              />
              <div
                className="absolute right-6 bottom-[17px] z-10 cursor-pointer"
                onClick={() => showPassword("confirm_password")}
              >
                <Icons name={confirmPass} />
              </div>
            </div>
          </div>
          {validation && <p className={`my-5 text-center font-light italic text-sm subpixel-antialiased tracking-wide ${validation == 'Password updated' ? 'text-green-600' : 'text-red-500'} `}>{validation}</p>}
          <div className="flex justify-center space-x-4 items-center">
            <button
              type="button"
              className="text-light-red text-18 tracking-wide border-b dark:border-[#5356fb29]  border-light-red "
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-gradient tracking-wide rounded-full w-[173px] h-[46px] flex justify-center items-center"
              onClick={handleResetPassword}
            >
              <span className="font-thin text-18 tracking-wide antialiased text-white">
              {loading ? <div className="signup btn-loader"></div> : <span>Change Password</span>}
              </span>
            </button>
          </div>
        </div>
        <div className="w-[440px] sm:flex hidden justify-end">
          <PasswordSvg />
        </div>
      </div>
    </div>
  );
}
