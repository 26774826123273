import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Layout from "../../Partials/Layout";
import UserWeightWidget from "./UserWeightWidget";
import TrackEntryWidget from "./TrackEntryWidget";
import UserRecentTracking from "./UserRecentTracking";
import usersService from "../../../services/UsersService";

export default function TrackAction() {
  const api = new usersService();
  let { trackpage } = useParams();
  const [selectedCategory, setSelTrackCat] = useState([]);

  const getTrackCat = async () => {
    try {
      const res = await api.refreshTrackCategory();
      if (res.status == 200) {
        var fliterCategory = res.data.filter(function (el) {
          return el.widget == trackpage;
        });
        //debugger;
        setSelTrackCat([{ data: fliterCategory[0] }]);
        return;
      }
    } catch (error) {
      // setIsLoading(false)
    }
  };

  useEffect(() => {
    getTrackCat();
  }, []);

  console.log("TRACK PAGE  = = ", trackpage);
  console.log("SELECTED TRACK PAGE  trackCat = = ", selectedCategory);
  const selectedEntry = selectedCategory[0]?.data; // better way to do this so thst null on promise will not kill the screen

  console.log("wait till you know whst page to show");
  //debugger;
  return (
    <>
      <Layout>
        <div className="my-wallet-wrapper w-full mb-10">
          <div className="main-wrapper w-full">
            <div className="balance-inquery w-full lg:h-[436px] lg:flex lg:space-x-11 mb-11">
              <div className="lg:w-1/2 h-full mb-10 lg:mb-0">
                <TrackEntryWidget selectedEntry={selectedEntry} />
              </div>
              <div className="flex-1">
                <div className="my-wallets w-full h-full bg-white dark:bg-dark-white   rounded-lg p-6">
                  <div className="mb-4">
                    <h1 className="text-xl font-bold tracking-wide   text-dark-gray dark:text-white">
                      {selectedCategory[0]?.data?.title}
                    </h1>
                  </div>
                  <div className="content-area">
                    <p className="text-[44px] font-bold text-white tracking-wide leading-10 mb-2" style={{ backgroundColor: 'blue', textAlign:"center",paddingTop:'50px' }}>
                      <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
                        <div className="input-item sm:w-1/2 w-full mb-5 sm:mb-0"></div>
                        <div className="input-item flex-1">
                          <button
                            type="button"
                            className="btn-login  rounded-[50px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center "
                          >
                            <span>Connect Device</span>
                          </button>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <UserRecentTracking />
          </div>
        </div>
      </Layout>
    </>
  );
}
