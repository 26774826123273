import React from "react";
import Axios from "axios";

class SiteService {
    constructor() {
        console.log("Site Service Entered");
    }
    // Blog Data {Get}
    blogData() {
        return this.getAuxEnd("/blogdata", null);
    }

    // Country Data {GET}
    countryData() {
        return this.getAuxEnd("/country", null);
    }

    // Contact Data{POST}
    contactData() {
        return this.postAuxEnd("/contact", null)
    }

    faqData() {
        return this.getAuxEnd("/faq", null);
    }

    priceData() {
        return this.getAuxEnd("/pricing", null);
    }

    //----------------------------------------  -----
    //----------------------------------------  -----
    // Unified call below
    //----------------------------------------  -----
    //----------------------------------------  -----
    getAuxEnd(uri, reqData) {
        const endPoint = process.env.REACT_APP_AUX_ENDPOINT + uri;
        return Axios.get(endPoint)
            .then((response) => {
                // console.log(response);
                // res = response;
                // console.log("~~~~~~~ Toks2 GET ~~~~~~~~");
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }

    postAuxEnd(uri, reqData) {
        const endPoint = process.env.REACT_APP_AUX_ENDPOINT + uri;
        return Axios.post(endPoint, reqData)
            .then((response) => {
                console.log(response);
                // res = response;
                console.log("~~~~~~~ Toks2 POST ~~~~~~~~");
                return response;
            })
            .catch((error) => {
                if (error.response) {
                    //response status is an error code
                    console.log(error.response.status);
                } else if (error.request) {
                    //response not received though the request was sent
                    console.log(error.request);
                } else {
                    //an error occurred when setting up the request
                    console.log(error.message);
                }
            });
    }
}

export default SiteService;
