import React, {useState, useEffect} from 'react'
import DatePicker from 'react-date-picker';
import Layout from '../Partials/Layout'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'

// import { toast } from 'react-toastify';

import InputCom from "../Helpers/Inputs/InputCom";
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import usersService from "../../services/UsersService";


export default function AddEditReminder({ className }) {
  let navigate = useNavigate()
  const api = new usersService();

  let {reminder_uuid} = useParams() // uuid of single reminder
  
  let location = useLocation()
 
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [mode, setMode] = useState([]) // for setting mode option display, content is updated when page loads

  const [category, setCategory] = useState([]) // for setting category option display, content is updated when page loads

  const [success, setSuccess] = useState(false)
  const [message, setMessage]=useState({
    status: false,
    message: ''
  })

  const [reminder, setReminder]=useState({
    'member_id': localStorage.getItem('member_id'),
    description: location.state?.reminder.description || '',
    notes: location.state?.reminder.notes || '',
    category: location.state?.reminder.category || '',
    mode: location.state?.reminder.mode || ''
  })
  // console.log(reminder)
  const onReminderInputChange = ({target:{name,value}}) => { //function to run when user changes any input on the add reminder page
    setReminder(prev=>(
      {...prev, [name]:value}
    ))
  }

  const getUserMode = async () => {
    try {
      const res = await api.getUserModeCategory('remmode');
      setMode(res.data);
    } catch (error) {
      console.log("Error getting mode");
    }
  };

  const getUserCategory = async () => {
    try {
      const res = await api.getUserModeCategory('remcategory');
      setCategory(res.data);
    } catch (error) {
      console.log("Error getting user category");
    }
  };

  const handleAddReminder = async () => { // function to add reminder, after all test cases are met
     // toast('Reminder Added')
     let infoDetail = reminder
     infoDetail.start_date = startDate
     infoDetail.end_date = endDate
     if(location.state){
      infoDetail.uuid = reminder_uuid
     }
    setSuccess(true)
    setMessage({status: true, message: ''})
    let {description, notes, category, mode} = infoDetail
    //CHECKING IF AN EMPTY FIELD WAS PASSED
    if(!description || !category || !mode){
      setSuccess(false)
      setMessage({status: false, message: 'All fields must be filled'})
      return
    }

    try {
      const res = await api.addReminder(infoDetail);
      if(res && res.status == 200){
        setSuccess(false)
        setMessage({status: true, message: 'Reminder set successfully'})
        setTimeout(()=>{
          navigate('/reminders', {replace: true})
        }, 2000)
        return
      }
      setSuccess(false)
      setMessage({status: false, message: `Sorry, couldn't perform action`})
    } catch (error) {
      setSuccess(false)
      setMessage({status: false, message: 'An error occurred'})
    }
  }

  
  
  useEffect(() => {
    getUserMode();
    getUserCategory()
  }, []);
  return (
    <>
      <Layout>
        <div className="content-wrapper-profile-only w-full mb-6">
        <div className="history-wrapper w-full mb-10">
            
        <div
      className={`update-table w-full p-8 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow min-h-[520px] ${
        className || ""
      }`}
    >
		  <div className="fields w-full">
               {/*description */}
               <div className="field w-full mb-6">
              <InputCom
                label="Description"
                type="text"
                name="description"
                placeholder="Describe the Reminder"
                value={reminder.description}
                inputHandler={onReminderInputChange}
                maxLength={100}
                borderColor='border border-pink'
              />
            </div>

             {/* first name and last name */}
             <div className="xl:flex xl:space-x-7 mb-6">
              <div className="field w-full reminder-select mb-6 xl:mb-0">
                {/* <InputCom
                  label="Reminder Type"
                  type="text"
                  name="remType"
                  placeholder="Drop down of Category"
                  value={''}
                /> */}
                <label className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5">Reminder Type</label>
                <select value={reminder.category} name='category' className='bg-[#fafafa] border-light-purple rounded-full pl-4 dark:bg-[#11131F] dark:text-[#7B818D] text-gray-700 w-full py-5 cursor-pointer focus:outline-none focus:ring-0' onChange={onReminderInputChange}>
                  <option className='' value="">Select category</option>
                  {category.length > 0 &&
                  <>
                    {category.map((option, index)=>(
                      <option key={index} className='' value={option.code}>{option.category}</option>
                    ))
                    }
                  </>
                  }
                </select>
              </div>
              <div className="field w-full reminder-select">
                {/* <InputCom
                  label="Mode"
                  type="text"
                  name="remMode"
                  placeholder="Drop down of Modes"
                  value={''}
                /> */}
                <label className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5">Mode</label>
                <select value={reminder.mode} name='mode' className='bg-[#fafafa] rounded-full pl-4 dark:bg-[#11131F] dark:text-[#7B818D] text-gray-700 w-full py-5 cursor-pointer focus:outline-none focus:ring-0' onChange={onReminderInputChange}>
                <option className='' value="">Select mode</option>
                  {category.length > 0 &&
                  <>
                    {mode.map((option, index)=>(
                      <option key={index} className='' value={option.code}>{option.mode}</option>
                    ))
                    }
                  </>
                  }
                </select>
              </div>
            </div>

            <div className="xl:flex xl:space-x-7 mb-6">
              <div className="field w-full mb-6 xl:mb-0">
                {/* <InputCom
                  label="Start Date"
                  type="text"
                  name="startDate"
                  placeholder="10-10-2021"
                  value={''}
                /> */}
                <label className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5">Start Date</label>
                {/* <Calendar onChange={setStartDate} value={reminder.start_date} calendarType="US" /> */}
                <DatePicker
                minDate={new Date()}
                minDetail="decade"
                format="y-MM-dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                dayPlaceholder="dd"
                range="range"
                clearIcon={startDate ? null : 'x'}
                value={startDate}
                onChange={(date) => setStartDate(date)}
                calendarIcon={<CalendarIcon />}
              />
              </div>
              <div className="field w-full">
                <label className="input-label text-dark-gray dark:text-white text-xl font-bold block mb-2.5">End Date</label>
                <DatePicker
                minDate={startDate}
                minDetail="decade"
                format="y-MM-dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                dayPlaceholder="dd"
                range="range"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                calendarIcon={<CalendarIcon />}
              />
              </div>
            </div>

            {/* bio */}
            <div className="field w-full mb-6">
              <h1 className="field-title">Optional Details </h1>
              <div className="input-field mt-2">
                <div className="input-wrapper w-full ">
                  <textarea
                    name='notes'
                    value={reminder.notes}
                    placeholder="Provide a detailed description of your item."
                    rows="7"
                    className="w-full h-full px-7 py-4  border border-pink dark:border-[#5356fb29]  rounded-[20px] text-dark-gray dark:text-white bg-[#FAFAFA] dark:bg-[#11131F]  focus:ring-0 focus:outline-none resize-none"
                    onChange={onReminderInputChange}
                    maxLength={250}
                  />
                </div>
              </div>
            </div>
              <p className={`text-center ${message.status ? 'text-[rgb(0,128,0)]':'text-[rgb(255,0,0)]'}`}>{message.message}</p>
          </div>
          {/* border line */}
          <div className="w-full h-[120px] border-t border-light-purple dark:border-[#5356fb29]  flex justify-end items-center">
          <div className="flex space-x-4 mr-9">
            <button
              type="button"
              className="text-18 text-light-red tracking-wide "
              onClick={() => navigate('/reminders')}
            >
              <span className="border-b dark:border-[#5356fb29]  border-light-red">
                {" "}
                Cancel
              </span>
            </button>
              {
                success ? 
                <div role="status">
                  <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span className="sr-only">Loading...</span>
              </div>
                :
              <button
                type="button"
                className="w-[152px] h-[46px] flex justify-center items-center btn-gradient text-base rounded-full text-white"
                onClick={handleAddReminder}
              >
                {location.state?.reminder ? 'Update' : 'Add Reminder'}
              </button>
            }
          </div>
        </div>
        </div>
</div>
        </div>
      </Layout>
    </>
  )
}

const CalendarIcon = () => (<><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 dark:text-[#374151]">
<path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
</svg>
</>)