import React, { useState } from "react";
import background from "../../../assets/images/shape/balance-bg.svg";
import InputCom from "../../../components/Helpers/Inputs/InputCom";
import dataImage2 from "../../../assets/images/data-table-user-2.png";


export default function TrackEntryWidget(props) {
  return (
    <div
      className="current-balance-widget w-full h-full rounded-2xl overflow-hidden flex flex-col justify-between  px-8 py-9"
      style={{
        background: `url(${background}) 0% 0% / cover no-repeat`,
      }}
    >
      <div className="wallet flex justify-between">
        <div className="w-[100px] h-[100px] rounded-full overflow-hidden flex justify-center items-center">
          <img src={dataImage2} alt="data" className="w-full h-full" />
        </div>
        <div>
          <p className="text-26 font-bold tracking-wide text-white text-right">
            {props.selectedEntry?.title} Entry
          </p>
        </div>
      </div>

      <div className="balance">
        <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
          <div className="input-item sm:w-1/2 w-full mb-5 sm:mb-0">
            <InputCom placeholder="" label="" name="val1" type="text" />
          </div>
          <div className="input-item flex-1">
            <InputCom
              placeholder="Drop down Units"
              label=""
              name="unitofmeasure"
              type="text"
            />
          </div>
        </div>
        <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
          <div className="input-item  w-full mb-5 sm:mb-0">
            <InputCom
              placeholder="date time selection"
              label=""
              name="event_time"
              type="text"
            />
          </div>
        </div>

        <p className="text-[44px] font-bold text-white tracking-wide leading-10 mb-2">
          <div className="input-fl-name mb-5 sm:flex w-full sm:space-x-6 ">
            <div className="input-item sm:w-1/2 w-full mb-5 sm:mb-0"></div>
            <div className="input-item flex-1">
              <button
                type="button"
                className="btn-login  rounded-[50px] mb-6 text-xl text-white font-bold flex justify-center bg-purple items-center "
              >
                <span>Add</span>
              </button>
            </div>
          </div>
        </p>
      </div>
    </div>
  );
}
