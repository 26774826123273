import React from "react";

export default function TermsConditionTab() {
  return (
    <div className="terms-conditon-tab w-full">
      <div className="terms-condition-wrappr w-full overflow-auto">
        <div className="mb-8">
          <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            1.
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] ">
          myFit . ("MERMS myFit") is committed to upholding the privacy
                rights of individuals. This Privacy Policy explains the
                collection, use, purpose, and sharing of personally identifiable
                information ("PII") related to the use of Merms's myFit website{" "}
                <span>
                  <a href={process.env.REACT_APP_APPSITE} target='_blank'>
                    {process.env.REACT_APP_APPSITE}
                  </a>
                </span>
                , MERMS providing of services to our users ("Partners"), or from
                our employees. PII does not include information that is
                anonymized or aggregated, and other information which is
                excluded from the scope of applicable privacy laws.
          </p><br/>
          <p className="text-base text-thin-light-gray leading-[28px]">
          myFit does not require you to register or provide PII to visit
                our website. By accessing our website or purchasing our
                services, you agree to this Privacy Policy in addition to any
                other agreements we might have with you. myFit complies with the
                EU-U.S. Privacy Shield Framework as set forth by the U.S.
                Department of Commerce regarding the collection, use, and
                retention of all personal information transferred from the
                European Union to the United States. myFit adheres to the
                Privacy Shield Principles, and is subject to the investigatory
                and enforcement powers of the Federal Trade Commission. If there
                is any conflict between the terms in this Privacy Policy and the
                Privacy Shield Principles, the Privacy Shield Principles shall
                govern. Provides its services (described below) to you through
                its website located at{" "}
                <span>
                  <a href={process.env.REACT_APP_APPSITE} target='_blank'>
                    {process.env.REACT_APP_APPSITE}
                  </a>
                </span>{" "}
                (the "Site") and through its mobile applications and related
                services (collectively, such services, including any new
                features and applications, and the Site, the "Service(s)"),
                subject to the following Terms of Service (as amended from time
                to time, the "Terms of Service").
          </p>
        </div>
        <div className="mb-8">
          <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            2. Additional Terms
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Your use of the Services is at all times subject to our separate
                Terms of Use or such other customer or user agreement between
                myFit and the entity through which you access and use the
                Services, which incorporates this Privacy Policy. We use any
                terms in this Privacy Policy without defining them with the
                definitions given to them in our Terms of Use.
          </p> 
          <h2 className="text-lg tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
          Access and Use of the Service
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px]">
          Services Description: Our Services are designed to provide you
                with personal health information platform service that helps you
                to manage the intricacies of your daily and long term health
                activities. It allows you to consolidate, track and optimize
                your health habits. Our Services may include health plan to
                track your health, organizing your prescription, health
                routines. Health tips, help you manage both general and the few
                health questions relevant to you. Health Records. Record you
                control your information. Your health record is yours at all
                point; you decide your provider access as needed from time to
                time. Add family record and determine how you want your
                providers to access your record, including Health Stats that
                collect your health statistics yourself, weight changes, blood
                pressure, blood glucose data, and ensure that your provider is
                creating plans that fit you precisely. We may provide certain
                recommendations based on your health habits. Our Services may
                also present you information relating to third-party products or
                services ("myFit Offers") that you may be interested in. The
                Services may also provide you with general tips, recommendations
                and educational material.
          </p><br />
          <p className="text-base text-thin-light-gray leading-[28px]">
                Access to Third Party Service Accounts: To use certain features
                of the Services, you may elect to grant myFit access to your
                accounts with certain third-party platforms for myFit to obtain
                certain history information about you. myFit will only use this
                information to provide you with the Services or in an aggregated
                and anonymized manner to generally improve its products and
                services. You represent that you are entitled to grant us access
                for this purpose, without any obligation by myFit to pay any
                fees or be subject to any restrictions or limitations. Using the
                Services, you expressly authorize myFit to access your account
                information maintained by identified third parties, on your
                behalf as your agent, and you expressly authorize such third
                parties to disclose your information to us. When you use the
                "Add Accounts" feature of the Services, you will be directly
                connected to the website for the third party you have
                identified. myFit will submit information including usernames
                and passwords that you provide to log into the Site. You hereby
                authorize and permit myFit to use and store the information
                submitted by you to accomplish the foregoing and configure the
                Services to be compatible with the third party sites for which
                you submit your information. For purposes of this Agreement and
                solely to provide the account information to you as part of the
                Services, you grant myFit a limited power of attorney, and
                appoint myFit as your attorney-in-fact and agent, to access
                third party sites, retrieve and use your information with the
                full power and authority to do and perform each thing necessary
                in connection with such activities, as you could do in person.
              </p><br/>

              {/* Acknowledgments */}
              <p className="text-base text-thin-light-gray leading-[28px]">
                YOU ACKNOWLEDGE AND AGREE THAT WHEN myFit IS ACCESSING AND
                RETRIEVING ACCOUNT INFORMATION FROM THIRD PARTY SITES, myFit IS
                ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF
                THE THIRD PARTY THAT OPERATES THE THIRD PARTY SITE. You
                understand and agree that the Services are not sponsored or
                endorsed by any third parties accessible through the Services.
                myFit is not responsible for any payment processing errors or
                fees or other Services-related issues, including those issues
                that may arise from inaccurate account information.
              </p><br/>

              <p className="text-base text-thin-light-gray leading-[28px]">
                Your Registration Obligations: You may be required to register
                with myFit to access and use certain Service features. If you
                choose to register for the Service, you agree to provide and
                maintain true, accurate, current and complete information about
                yourself as prompted by the Service's registration form. Our
                Privacy Policy governs registration data and certain other
                information about you. If you are under 13 years of age, you
                cannot use the Service, with or without registering. Also, if
                you are under 18 years old, you may use the Service, with or
                without registering, only with your parent or guardian's
                approval.
              </p><br />

              <p className="text-base text-thin-light-gray leading-[28px]">
                Member Account, Password and Security: You are responsible for
                maintaining the confidentiality of your password and account, if
                any, and are fully responsible for any activities that occur
                under your password or account. You agree to (a) immediately
                notify myFit of any unauthorized use of your password or account
                or any other breach of security, and (b) ensure that you exit
                from your account at the end of each session when accessing the
                Service. myFit will not be liable for any loss or damage arising
                from your failure to comply with this Section.
              </p><br />

              <p className="text-base text-thin-light-gray leading-[28px]">
                Modifications to Service: myFit reserves the right to modify or
                discontinue, temporarily or permanently, the Service (or any
                part thereof) with or without notice. You agree that myFit will
                not be liable to you or to any third party for any modification,
                suspension or discontinuance of the Service.
              </p><br/>

              <p className="text-base text-thin-light-gray leading-[28px]">
                General Practices Regarding Use and Storage: You acknowledge
                that myFit may establish general practices and limits concerning
                use of the Service, including without limitation the maximum
                period of time that data or other content will be retained by
                the Service and the maximum storage space that will be allotted
                on myFit servers on your behalf. You agree that myFit has no
                responsibility or liability for the deletion or failure to store
                any data or other content maintained or uploaded by the Service.
                You acknowledge that myFit reserves the right to terminate
                accounts that are inactive for an extended period of time. You
                further acknowledge that myFit reserves the right to change
                these general practices and limits at any time, in its sole
                discretion, with or without notice.
              </p>
              <br />

              <p className="text-base text-thin-light-gray leading-[28px] mb-4">
                Mobile Services: The Service includes certain services that are
                available via a mobile device, including (i) the ability to
                upload content to the Service via a mobile device, (ii) the
                ability to browse the Service and the Site from a mobile device
                and (iii) the ability to access certain features through an
                application downloaded and installed on a mobile device
                (collectively, the Mobile Services). To the extent you access
                the Service through a mobile device, your wireless service
                carrier standard charges, data rates and other fees may apply.
                In addition, downloading, installing, or using certain Mobile
                Services may be prohibited or restricted by your carrier, and
                not all Mobile Services may work with all carriers or devices.
                By using the Mobile Services, you agree that we may communicate
                with you regarding myFit and other entities by SMS, MMS, text
                message or other electronic means to your mobile device and that
                certain information about your usage of the Mobile Services may
                be communicated to us. In the event you change or deactivate
                your mobile telephone number, you agree to promptly update your
                myFit account information to ensure that your messages are not
                sent to the person that acquires your old number.
              </p>
        </div>

        {/* Purple Banner */}
        {/* <div className="mb-8 bg-purple p-[23px] text-white rounded-lg">
          <p className="text-[18px]">
            These Terms and Conditions are related to the sale of NFTs by the
            Company (the “Company”) on its Website. It solely governs the
            contractual relationship between You and the Company regarding the
            Sale and any related contract.
          </p>
        </div> */}
        <div className="mb-8">
          <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            3. Conditions of Use
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
             User Conduct: You are solely responsible for all code, video,
                images, information, data, text, software, music, sound,
                photographs, graphics, messages or other materials (Content)
                that you upload, post, publish or display (hereinafter, upload)
                or email or otherwise use via the Service. The following are
                examples of the kind of content and/or use that is illegal or
                prohibited by myFit. myFit reserves the right to investigate and
                take appropriate legal action against anyone who, in myFit sole
                discretion, violates this provision, including without
                limitation, removing the offending content from the Service,
                suspending or terminating the account of such violators and
                reporting you to the law enforcement authorities. You agree to
                not use the Service to email or otherwise upload any content
                that:
                 {/* Purple Banner */}
        <div className="bg-purple px-8 py-4 text-white rounded-lg my-4">
                <ul className="text-[18px] flex flex-col gap-2 list-outside list-[upper-roman]">
                  <li>
                    <p>
                      infringes any intellectual property or other proprietary
                      rights of any party;{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      you do not have a right to upload under any law or under
                      contractual or fiduciary relationships;
                    </p>
                  </li>
                  <li>
                    <p>
                      contains software viruses or any other computer code,
                      files or programs designed to interrupt, destroy or limit
                      the functionality of any computer software or hardware or
                      telecommunications equipment;
                    </p>
                  </li>
                  <li>
                    <p>
                      poses or creates a privacy or security risk to any person;
                    </p>
                  </li>
                  <li>
                    <p>
                      constitutes unsolicited or unauthorized advertising,
                      promotional materials, commercial activities and/or sales,
                      junk mail, spam, chain letters, pyramid
                      schemes,contests,sweepstakes, or any other form of
                      solicitation;
                    </p>
                  </li>
                  <li>
                    <p>
                      is unlawful, harmful, threatening, abusive, harassing,
                      tortious, excessively violent, defamatory, vulgar,
                      obscene, pornographic, libelous, invasive of another
                      privacy, hateful racially, ethnically or otherwise
                      objectionable;
                    </p>
                  </li>
                  <li>
                    <p>
                      in the sole judgment of myFit, is objectionable or which
                      restricts or inhibits any other person from using or
                      enjoying the Service, or which may expose myFit or its
                      users to any harm or liability of any type; interfere with
                      or disrupt the Service or servers or networks connected to
                      the Service, or disobey any requirements, procedures,
                      policies or regulations of networks connected to the
                      Service; or violate any applicable local, state, national
                      or international law, or any regulations having the force
                      of law; impersonate any person or entity, or falsely state
                      or otherwise misrepresent your affiliation with a person
                      or entity; solicit personal information from anyone under
                      the age of 18;
                    </p>
                  </li>
                </ul>
        </div>
        harvest or collect email addresses or other contact information
                of other users from the Service by electronic or other means for
                the purposes of sending unsolicited emails or other unsolicited
                communications; advertise or offer to sell or buy any goods or
                services for any business purpose that is not specifically
                authorized; further or promote any criminal activity or
                enterprise or provide instructional information about illegal
                activities; or obtain or attempt to access or otherwise obtain
                any materials or information through any means not intentionally
                made available or provided for through the Service.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Fees: To the extent the Service or any portion thereof is made
                available for any fee, you will be required to select a payment
                plan and provide myFit information regarding your credit card or
                other payment instrument. You represent and warrant to myFit
                that such information is true and that you are authorized to use
                the payment instrument. You will promptly update your account
                information with any changes (for example, a change in your
                billing address or credit card expiration date) that may occur.
                You agree to pay myFit the amount that is specified in the
                payment plan in accordance with the terms of such plan and this
                Terms of Service. You hereby authorize myFit to bill your
                payment instrument in advance on a periodic basis in accordance
                with the terms of the applicable payment plan until you
                terminate your account, and you further agree to pay any charges
                so incurred If you dispute any charges you must let myFit know
                within sixty (60) days after the date that myFit charges you. We
                reserve the right to change myFit prices. If myFit does change
                prices, myFit will provide notice of the change on the Site or
                in email to you, at myFit option, at least 30 days before the
                change is to take effect. Your continued use of the Service
                after the price change becomes effective constitutes your
                agreement to pay the changed amount. You shall be responsible
                for all taxes associated with the Services other than U.S. taxes
                based on myFit net income.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Special Notice for International Use; Export Controls: Software
                (defined below) available in connection with the Service and the
                transmission of applicable data, if any, is subject to United
                States export controls. No Software may be downloaded from the
                Service or otherwise exported or re-exported in violation of
                U.S. export laws. Downloading or using the Software is at your
                sole risk. Recognizing the global nature of the Internet, you
                agree to comply with all local rules and laws regarding your use
                of the Service, including as it concerns online conduct and
                acceptable content.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Rewards Program: Your account with myFit may accrue cash rewards
                in a variety of ways (Rewards). You can earn Rewards by changing
                your mobility behaviour in specific ways as suggested by myFit.
                The details of the Rewards are set forth on the myFit app and
                website, and you can track your Rewards in progress in you
                Special Notice for International Use; Export Controls: Software
                (defined below) available in connection with the Service and the
                transmission of applicable data, if any, is subject to United
                States export controls. No Software may be downloaded from the
                Service or otherwise exported or re-exported in violation of
                U.S. export laws. Downloading or using the Software is at your
                sole risk. Recognizing the global nature of the Internet, you
                agree to comply with all local rules and laws regarding your use
                of the Service, including as it concerns online conduct and
                acceptable content. r account Rewards page. myFit shall pay you
                the Rewards, upon request, whenever they accrue at least 1000
                points, to be paid out in cash and sent to your bank account
                listed in your account.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          No Commercial Use: The Service is for your personal use only.
                Unless otherwise expressly authorized herein or in the Service,
                you agree not to display, distribute, license, perform, publish,
                reproduce, duplicate, copy, create derivative works from,
                modify, sell, resell, exploit, transfer or upload for any
                commercial purposes, any portion of the Service, use of the
                Service, or access to the Service
          </p>
        </div>
        <div className="mb-8">
          <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            4. Third-Party Distribution Channels
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          myFit offers Software applications that may be made available
                through the Android App Store, Android Marketplace or other
                distribution channels (Distribution Channels). If you obtain
                such Software through a Distribution Channel, you may be subject
                to additional terms of the Distribution Channel. These Terms of
                Service are between you and us only, and not with the
                Distribution Channel. To the extent that you utilize any other
                third party products and services in connection with your use of
                our Services, you agree to comply with all applicable terms of
                any agreement for such third party products and services.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          With respect to Software that is made available for your use in
                connection with an Android-branded product (such Software,
                Android-Enabled Software), in addition to the other terms and
                conditions set forth in these Terms of Service, the following
                terms and conditions apply:

          <div className="bg-purple px-8 py-4 text-white rounded-lg my-4">
                <ul className="text-[18px] flex flex-col gap-2 list-outside list-[upper-roman]">
                <li>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled"></i>
                    </span>{" "}
                    myFit and you acknowledge that these Terms of Service are
                    concluded between myFit and you only, and not with Android
                    Inc. (Android), and that as between myFit and Android,
                    myFit, not Android, is solely responsible for the
                    Android-Enabled Software and the content thereof.
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled"></i>
                    </span>{" "}
                    You may not use the Android-Enabled Software in any manner
                    that is in violation of or inconsistent with the Usage Rules
                    set forth for Android-Enabled Software in, or otherwise be
                    in conflict with, the App Store Terms of Service.
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled"></i>
                    </span>{" "}
                    Your license to use the Android-Enabled Software is limited
                    to a non-transferable license to use the Android-Enabled
                    Software on an Android Product that you own or control, as
                    permitted by the Usage Rules set forth in the App Store
                    Terms of Service.
                  </p>
                </li>
                <li>
                  <p>
                    {" "}
                    <span className="icon">
                      <i className="icofont-check-circled"></i>
                    </span>{" "}
                    Android has no obligation whatsoever to provide any
                    maintenance or support services with respect to the
                    Android-Enabled Software.
                  </p>
                </li>
                </ul>
        </div>
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Android is not responsible for any product warranties, whether
                express or implied by law. In the event of any failure of the
                Android-Enabled Software to conform to any applicable warranty,
                you may notify Android, and Android will refund the purchase
                price for the Android-Enabled Software to you, if any; and, to
                the maximum extent permitted by applicable law, Android will
                have no other warranty obligation whatsoever with respect to the
                Android-Enabled Software, or any other claims, losses,
                liabilities, damages, costs or expenses attributable to any
                failure to conform to any warranty, which will be myFit sole
                responsibility, to the extent it cannot be disclaimed under
                applicable law.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          myFit and you acknowledge that myFit, not Android, is
                responsible for addressing any claims of you or any third party
                relating to the Android-Enabled Software or your possession
                and/or use of that Android-Enabled Software, including, but not
                limited to: (i) product liability claims; (ii) any claim that
                the Android-Enabled Software fails to conform to any applicable
                legal or regulatory requirement; and (iii) claims arising under
                consumer protection or similar legislation.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          In the event of any third party claim that the Android-Enabled
                Software or the end-users possession and use of that
                Android-Enabled Software infringes that third partys
                intellectual property rights, as between myFit and Android,
                myFit, not Android, will be solely responsible for the
                investigation, defense, settlement and discharge of any such
                intellectual property infringement claim.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          You represent and warrant that (i) you are not located in a
                country that is subject to a U.S. Government embargo, or that
                has been designated by the U.S. Government as a terrorist
                supporting country; and (ii) you are not listed on any U.S.
                Government list of prohibited or restricted parties.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          If you have any questions, complaints or claims with respect to
                the Android-Enabled Software, they should be directed to myFit
                as follows:
                <br />
                <a href="mailto: support@mermsemr.com" target="_blank" rel="noopener noreferrer" className="text-[light-purple]">support@mermsemr.com</a>
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          myFit and you acknowledge and agree that Android, and Androids
                subsidiaries, are third-party beneficiaries of these Terms of
                Service with respect to the Android-Enabled Software, and that,
                upon your acceptance of the terms and conditions of these Terms
                of Service, Android will have the right (and will be deemed to
                have accepted the right) to enforce these Terms of Service
                against you with respect to the Android-Enabled Software as a
                third party beneficiary thereof.
          </p>
        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            5. Intellectual Property Rights
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Service Content, Software and Trademarks: You acknowledge and
                agree that the Service may contain content or features (Service
                Content) that are protected by copyright, patent, trademark,
                trade secret or other proprietary rights and laws. Except as
                expressly authorized by myFit, you agree not to modify, copy,
                frame, scrape, rent, lease, loan, sell, distribute or create
                derivative works based on the Service or the Service Content, in
                whole or in part, except that the foregoing does not apply to
                your own User Content (as defined below) that you legally upload
                to the Service. In connection with your use of the Service you
                will not engage in or use any data mining, robots, scraping or
                similar data gathering or extraction methods. If you are blocked
                by myFit from accessing the Service (including by blocking your
                IP address), you agree not to implement any measures to
                circumvent such blocking (e.g., by masking your IP address or
                using a proxy IP address). Any use of the Service or the Service
                Content other than as specifically authorized herein is strictly
                prohibited. The technology and software underlying the Service
                or distributed in connection therewith are the property of
                myFit, our affiliates and our partners (the Software). You agree
                not to copy, modify, create a derivative work of, reverse
                engineer, reverse assemble or otherwise attempt to discover any
                source code, sell, assign, sublicense, or otherwise transfer any
                right in the Software. Any rights not expressly granted herein
                are reserved by myFit.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          The myFit name and logos are trademarks and service marks of
                myFit (collectively the myFit Trademarks). Other company,
                product, and service names and logos used and displayed via the
                Service may be trademarks or service marks of their respective
                owners who may or may not endorse or be affiliated with or
                connected to myFit. Nothing in this Terms of Service or the
                Service should be construed as granting, by implication,
                estoppel, or otherwise, any license or right to use any of myFit
                Trademarks displayed on the Service, without our prior written
                permission in each instance. All goodwill generated from the use
                of myFit Trademarks will inure to our exclusive benefit.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Third-Party Material: Under no circumstances will myFit be
                liable in any way for any content or materials of any third
                parties (including users), including, but not limited to, for
                any errors or omissions in any content, or for any loss or
                damage of any kind incurred as a result of the use of any such
                content. You acknowledge that myFit does not pre-screen Content,
                but that myFit and its designees will have the right (but not
                the obligation) in their sole discretion to refuse or remove any
                content that is available via the Service. Without limiting the
                foregoing, myFit and its designees will have the right to remove
                any content that violates these Terms of Service or is deemed by
                myFit, in its sole discretion, to be otherwise objectionable.
                You agree that you must evaluate, and bear all risks associated
                with, the use of any content, including any reliance on the
                accuracy, completeness, or usefulness of such content.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          User Content Transmitted Through the Service: With respect to
                the content or other materials you upload through the Service or
                share with other users or recipients (collectively, User
                Content), you represent and warrant that you own all right,
                title and interest in and to such User Content, including,
                without limitation, all copyrights and rights of publicity
                contained therein. By uploading any User Content you hereby
                grant and will grant myFit and its affiliated companies a
                nonexclusive, worldwide, royalty-free, fully paid up,
                transferable, sublicensable, perpetual, irrevocable license to
                copy, display, upload, perform, distribute, store, modify and
                otherwise use your User Content in connection with the operation
                of the Service, in any form, medium or technology now known or
                later developed.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          You acknowledge and agree that any questions, comments,
                suggestions, ideas, feedback or other information about the
                Service (Submissions), provided by you to myFit are
                non-confidential and myFit will be entitled to the unrestricted
                use and dissemination of these Submissions for any purpose,
                commercial or otherwise, without acknowledgment or compensation
                to you.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          You acknowledge and agree that myFit may preserve Content and
                may also disclose Content if required to do so by law or in the
                good faith belief that such preservation or disclosure is
                reasonably necessary to: (a) comply with legal process,
                applicable laws or government requests; (b) enforce these Terms
                of Service; (c) respond to claims that any content violates the
                rights of third parties; or (d) protect the rights, property, or
                personal safety of myFit, its users and the public. You
                understand that the technical processing and transmission of the
                Service, including your content, may involve (a) transmissions
                over various networks; and (b) changes to conform and adapt to
                technical requirements of connecting networks or devices.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Copyright Complaints: myFit respects the intellectual property
                of others, and we ask our users to do the same. If you believe
                that your work has been copied in a way that constitutes
                copyright infringement, or that your intellectual property
                rights have been otherwise violated, you should notify myFit of
                your infringement claim in accordance with the procedure set
                forth below.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          myFit will process and investigate notices of alleged
                infringement and will take appropriate actions under the Digital
                Millennium Copyright Act (DMCA) and other applicable
                intellectual property laws with respect to any alleged or actual
                infringement. A notification of claimed copyright infringement
                should be emailed to myFit Copyright Agent at support@myFit.com
                (Subject line: DMCA Takedown Request). You may also contact us
                by mail or facsimile at:
                <br />
                <a href="mailto: support@mermsemr.com" target="_blank" rel="noopener noreferrer" className="text-[light-purple]">support@mermsemr.com</a>
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          To be effective, the notification must be in writing and contain
                the following information: an electronic or physical signature
                of the person authorized to act on behalf of the owner of the
                copyright or other intellectual property interest; a description
                of the copyrighted work or other intellectual property that you
                claim has been infringed; a description of where the material
                that you claim is infringing is located on the Service, with
                enough detail that we may find it on the Service; your address,
                telephone number, and email address; a statement by you that you
                have a good faith belief that the disputed use is not authorized
                by the copyright or intellectual property owner, its agent, or
                the law; a statement by you, made under penalty of perjury, that
                the above information in your Notice is accurate and that you
                are the copyright or intellectual property owner or authorized
                to act on the copyright or intellectual property owners behalf.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Counter-Notice: If you believe that your User Content that was
                removed (or to which access was disabled) is not infringing, or
                that you have the authorization from the copyright owner, the
                copyright owners agent, or pursuant to the law, to upload and
                use the content in your User Content, you may send a written
                counter-notice containing the following information to the
                Copyright Agent:
                <br />
                your physical or electronic signature; identification of the
                content that has been removed or to which access has been
                disabled and the location at which the content appeared before
                it was removed or disabled; a statement that you have a good
                faith belief that the content was removed or disabled as a
                result of mistake or a misidentification of the content; and
                your name, address, telephone number, and email address, a
                statement that you consent to the jurisdiction of the federal
                court located within Northern District of California and a
                statement that you will accept service of process from the
                person who provided notification of the alleged infringement.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          If a counter-notice is received by the Copyright Agent, myFit
                will send a copy of the counter-notice to the original
                complaining party informing that person that it may replace the
                removed content or cease disabling it in 10 business days.
                Unless the copyright owner files an action seeking a court order
                against the content provider, member or user, the removed
                content may be replaced, or access to it restored, in 10 to 14
                business days or more after receipt of the counter-notice, at
                our sole discretion.
          </p>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Repeat Infringer Policy: In accordance with the DMCA and other
                applicable law, myFit has adopted a policy of terminating, in
                appropriate circumstances and at myFit's sole discretion, users
                who are deemed to be repeat infringers. myFit may also at its
                sole discretion limit access to the Service and/or terminate the
                memberships of any users who infringe any intellectual property
                rights of others, whether or not there is any repeat
                infringement.
          </p>
        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            6. Third-Party Websites
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
The Service may provide, or third parties may provide, links or
                other access to other sites and resources on the Internet. myFit
                has no control over such sites and resources and myFit is not
                responsible for and does not endorse such sites and resources.
                You further acknowledge and agree that myFit will not be
                responsible or liable, directly or indirectly, for any damage or
                loss caused or alleged to be caused by or in connection with use
                of or reliance on any content, events, goods or services
                available on or through any such site or resource. Any dealings
                you have with third parties found while using the Service are
                between you and the third-party, and you agree that myFit is not
                liable for any loss or claim that you may have against any such
                third party.
</p>

        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            7. Social Networking Services
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
You may enable or log in to the Service via various online
                third-party services, such as social media and social networking
                services like Facebook or Twitter (Social Networking Services).
                By logging in or directly integrating these Social Networking
                Services into the Service, we make your online experiences
                richer and more personalized. To take advantage of this feature
                and capabilities, we may ask you to authenticate, register for
                or log into Social Networking Services on the websites of their
                respective providers. As part of such integration, the Social
                Networking Services will provide us with access to certain
                information that you have provided to such Social Networking
                Services, and we will use, store and disclose such information
                in accordance with our Privacy Policy. For more information
                about the implications of activating these Social Networking
                Services and myFit use, storage and disclosure of information
                related to you and your use of such services within myFit
                (including your friend lists and the like), please see our
                Privacy Policy. However, please remember that the manner in
                which Social Networking Services use, store and disclose your
                information is governed solely by the policies of such third
                parties, and myFit shall have no liability or responsibility for
                the privacy practices or other actions of any third-party site
                or service that may be enabled within the Service.
</p>
<p className="text-base text-thin-light-gray leading-[28px]">
In addition, myFit is not responsible for the accuracy,
                availability or reliability of any information, content, goods,
                data, opinions, advice or statements made available in
                connection with Social Networking Services. As such, myFit is
                not liable for any damage or loss caused or alleged to be caused
                by or in connection with use of or reliance on any such Social
                Networking Services. myFit enables these features merely as a
                convenience and the integration or inclusion of such features
                does not imply an endorsement or recommendation.
</p>
        </div>

        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            8. Indemnity and Release
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
You agree to release, indemnify and hold myFit and its
                affiliates and their officers, employees, directors and agents
                (collectively, Indemnitees) harmless from any from any and all
                losses, damages, expenses, including reasonable attorneys fees,
                rights, claims, actions of any kind and injury (including death)
                arising out of or relating to your use of the Service, any User
                Content, your connection to the Service, your violation of these
                Terms of Service or your violation of any rights of another.
                Notwithstanding the foregoing, you will have no obligation to
                indemnify or hold harmless any Indemnitee from or against any
                liability, losses, damages or expenses incurred as a result of
                any action or inaction of such Indemnitee. If you are a
                California resident, you waive California Civil Code Section
                1542, which says: A general release does not extend to claims
                which the creditor does not know or suspect to exist in his
                favor at the time of executing the release, which if known by
                him must have materially affected his settlement with the
                debtor. If you are a resident of another jurisdiction, you waive
                any comparable statute or doctrine.
</p>
        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            9. Disclaimer of Warranties
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
                PROVIDED ON AN AS IS AND AS AVAILABLE BASIS. myFit EXPRESSLY
                DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED
                OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                TITLE AND NON-INFRINGEMENT.
                <br />
                <br />
                myFit MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR
                REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
                SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED
                FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR
                (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR
                OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE
                WILL MEET YOUR EXPECTATIONS.
</p>
        </div>

        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            10. Limitation of Liability
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
YOU EXPRESSLY UNDERSTAND AND AGREE THAT myFit WILL NOT BE LIABLE
                FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY
                DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT
                LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER
                INTANGIBLE LOSSES (EVEN IF myFit HAS BEEN ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT,
                NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I)
                THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF
                PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY
                GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
                MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM
                THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD
                PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE
                SERVICE. IN NO EVENT WILL myFitS TOTAL LIABILITY TO YOU FOR ALL
                DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE
                PAID myFit IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE
                HUNDRED DOLLARS ($100).
</p>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
                CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
                FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
                THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE
                ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH
                ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR
                SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
</p>
<p className="text-base text-thin-light-gray leading-[28px]">
IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED
                DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY ARE
                INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF
                THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS
                HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY,
                THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF
                THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
</p>
        </div>
        <p className="text-base text-thin-light-gray leading-[28px] my-4">
        Dispute Resolution By Binding Arbitration:{" "}
              <b>
                PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
              </b>
        </p>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            11. Agreement to Arbitrate
          </h1>
          <p className="text-base text-thin-light-gray leading-[28px]">
          This Dispute Resolution by Binding Arbitration section is
                referred to in this Terms of Service as the Arbitration
                Agreement. You agree that any and all disputes or claims that
                have arisen or may arise between you and myFit, whether arising
                out of or relating to this Terms of Service (including any
                alleged breach thereof), the Services, any advertising, any
                aspect of the relationship or transactions between us, shall be
                resolved exclusively through final and binding arbitration,
                rather than a court, in accordance with the terms of this
                Arbitration Agreement, except that you may assert individual
                claims in small claims court, if your claims qualify. Further,
                this Arbitration Agreement does not preclude you from bringing
                issues to the attention of federal, state, or local agencies,
                and such agencies can, if the law allows, seek relief against us
                on your behalf. You agree that, by entering into this Terms of
                Service, you and myFit are each waiving the right to a trial by
                jury or to participate in a class action. Your rights will be
                determined by a neutral arbitrator, not a judge or jury. The
                Federal Arbitration Act governs the interpretation and
                enforcement of this Arbitration Agreement.
</p>
<h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
Prohibition of Class and Representative Actions and
                Non-Individualized Relief
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          YOU AND myFit AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE
                OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
                CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR
                PROCEEDING. UNLESS BOTH YOU AND myFit AGREE OTHERWISE, THE
                ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSONS OR
                PARTY'S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO, THE
                ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
                DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
                SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
                RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S), EXCEPT
                THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD
                PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT
                REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
        </p>
        <h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
        Pre-Arbitration Dispute Resolution
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          myFit is always interested in resolving disputes amicably and
                efficiently, and most customer concerns can be resolved quickly
                and to the customer's satisfaction by emailing customer support
                at <a href="mailto: support@mymyFit.com" target="_blank" rel="noopener noreferrer" className="text-[light-purple]">support@mymyFit.com</a>. If such efforts prove unsuccessful, a
                party who intends to seek arbitration must first send to the
                other, by certified mail, a written Notice of Dispute
                ("Notice"). The Notice to myFit should be sent to 1070 Crestal
                Way, Unit 2, San Rafael, CA 94903 ("Notice Address"). The Notice
                must (i) describe the nature and basis of the claim or dispute
                and (ii) set forth the specific relief sought. If myFit and you
                do not resolve the claim within sixty (60) calendar days after
                the Notice is received, you or myFit may commence an arbitration
                proceeding. During the arbitration, the amount of any settlement
                offer made by myFit or you shall not be disclosed to the
                arbitrator until after the arbitrator determines the amount, if
                any, to which you or myFit is entitled.
        </p>
        <h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
        Arbitration Procedures
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Arbitration will be conducted by a neutral arbitrator in
                accordance with the American Arbitration Associations (AAA)
                rules and procedures, including the AAAs Consumer Arbitration
                Rules (collectively, the AAA Rules), as modified by this
                Arbitration Agreement. For information on the AAA, please visit
                its website, <a href="http://www.adr.org" target="_blank" rel="noopener noreferrer" className="text-[light-purple]">http://www.adr.org</a>. Information about the AAA Rules
                and fees for consumer disputes can be found at the AAAs consumer
                arbitration page, <a href="http://www.adr.org/consumer_arbitration" target="_blank" rel="noopener noreferrer" className="text-[light-purple]">http://www.adr.org/consumer_arbitration</a>. If
                there is any inconsistency between any term of the AAA Rules and
                any term of this Arbitration Agreement, the applicable terms of
                this Arbitration Agreement will control unless the arbitrator
                determines that the application of the inconsistent Arbitration
                Agreement terms would not result in a fundamentally fair
                arbitration. The arbitrator must also follow the provisions of
                these Terms of Service as a court would. All issues are for the
                arbitrator to decide, including, but not limited to, issues
                relating to the scope, enforceability, and arbitrarily of this
                Arbitration Agreement. Although arbitration proceedings are
                usually simpler and more streamlined than trials and other
                judicial proceedings, the arbitrator can award the same damages
                and relief on an individual basis that a court can award to an
                individual under the Terms of Service and applicable law.
                Decisions by the arbitrator are enforceable in court and may be
                overturned by a court only for very limited reasons.
                <br />
                <br />
                Unless myFit and you agree otherwise, any arbitration hearings
                will take place in a reasonably convenient location for both
                parties with due consideration of their ability to travel and
                other pertinent circumstances. If the parties are unable to
                agree on a location, the determination shall be made by AAA. If
                your claim is for $10,000 or less, myFit agrees that you may
                choose whether the arbitration will be conducted solely on the
                basis of documents submitted to the arbitrator, through a
                telephonic hearing, or by an in-person hearing as established by
                the AAA Rules. If your claim exceeds $10,000, the right to a
                hearing will be determined by the AAA Rules. Regardless of the
                manner in which the arbitration is conducted, the arbitrator
                shall issue a reasoned written decision sufficient to explain
                the essential findings and conclusions on which the award is
                based.
        </p>
        <h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
        Costs of Arbitration
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Payment of all filing, administration, and arbitrator fees
                (collectively, the Arbitration Fees) will be governed by the AAA
                Rules, unless otherwise provided in this Arbitration Agreement.
                If the value of the relief sought is $75,000 or less, at your
                request, myFit will pay all Arbitration Fees. If the value of
                relief sought is more than $75,000 and you are able to
                demonstrate to the arbitrator that you are economically unable
                to pay your portion of the Arbitration Fees or if the arbitrator
                otherwise determines for any reason that you should not be
                required to pay your portion of the Arbitration Fees, myFit will
                pay your portion of such fees. In addition, if you demonstrate
                to the arbitrator that the costs of arbitration will be
                prohibitive as compared to the costs of litigation, myFit will
                pay as much of the Arbitration Fees as the arbitrator deems
                necessary to prevent the arbitration from being
                cost-prohibitive. Any payment of attorneys fees will be governed
                by the AAA Rules.
        </p>
        <h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
        Confidentiality
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          All aspects of the arbitration proceeding, and any ruling,
                decision, or award by the arbitrator, will be strictly
                confidential for the benefit of all parties.
        </p>
        <h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
        Severability
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          If a court or the arbitrator decides that any term or provision
                of this Arbitration Agreement (other than the subsection (b)
                titled -Prohibition of Class and Representative Actions and
                Non-Individualized Relief above) is invalid or unenforceable,
                the parties agree to replace such term or provision with a term
                or provision that is valid and enforceable and that comes
                closest to expressing the intention of the invalid or
                unenforceable term or provision, and this Arbitration Agreement
                shall be enforceable as so modified. If a court or the
                arbitrator decides that any of the provisions of subsection (b)
                above titled- Prohibition of Class and Representative Actions
                and Non-Individualized Relief are invalid or unenforceable, then
                the entirety of this Arbitration Agreement shall be null and
                void, unless such provisions are deemed to be invalid or
                unenforceable solely with respect to claims for public
                injunctive relief. The remainder of the Terms of Service will
                continue to apply.
        </p>
        <h2 className="text-xl tracking-wide font-semibold text-dark-gray dark:text-white mb-4">
        Future Changes to Arbitration Agreement
          </h2>
          <p className="text-base text-thin-light-gray leading-[28px] mb-4">
          Notwithstanding any provision in this Terms of Service to the
                contrary, myFit agrees that if it makes any future change to
                this Arbitration Agreement (other than a change to the Notice
                Address) while you are a user of the Services, you may reject
                any such change by sending myFit written notice within thirty
                (30) calendar days of the change to the Notice Address provided
                above. By rejecting any future change, you are agreeing that you
                will arbitrate any dispute between us in accordance with the
                language of this Arbitration Agreement as of the date you first
                accepted these Terms of Service (or accepted any subsequent
                changes to these Terms of Service).
        </p>
        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            12. Termination
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
You agree that myFit, in its sole discretion, may suspend or
                terminate your account (or any part thereof) or use of the
                Service and remove and discard any content within the Service,
                for any reason, including, without limitation, for lack of use
                or if myFit believes that you have violated or acted
                inconsistently with the letter or spirit of these Terms of
                Service. Any suspected fraudulent, abusive or illegal activity
                that may be grounds for termination of your use of Service, may
                be referred to appropriate law enforcement authorities. myFit
                may also in its sole discretion and at any time discontinue
                providing the Service, or any part thereof, with or without
                notice. You agree that any termination of your access to the
                Service under any provision of this Terms of Service may be
                effected without prior notice, and acknowledge and agree that
                myFit may immediately deactivate or delete your account and all
                related information and files in your account and/or bar any
                further access to such files or the Service. Further, you agree
                that myFit will not be liable to you or any third party for any
                termination of your access to the Service.
</p>
        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            13. User Disputes
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
You agree that you are solely responsible for your interactions
                with any other user in connection with the Service and myFit
                will have no liability or responsibility with respect thereto.
                myFit reserves the right, but has no obligation, to become
                involved in any way with disputes between you and any other user
                of the Service.
</p>
        </div>
        <div className="mb-8">
        <h1 className="text-xl tracking-wide font-bold text-dark-gray dark:text-white mb-4">
            14. General
          </h1>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
These Terms of Service constitute the entire agreement between
                you and myFit and govern your use of the Service, superseding
                any prior agreements between you and myFit with respect to the
                Service. You also may be subject to additional terms and
                conditions that may apply when you use affiliate or third-party
                services, third-party content or third party software. These
                Terms of Service will be governed by the laws of the State of
                California without regard to its conflict of law provisions.
                With respect to any disputes or claims not subject to
                arbitration, as set forth above, you and myFit agree to submit
                to the personal and exclusive jurisdiction of the state and
                federal courts located within San Francisco County, California.
                The failure of myFit to exercise or enforce any right or
                provision of these Terms of Service will not constitute a waiver
                of such right or provision. If any provision of these Terms of
                Service is found by a court of competent jurisdiction to be
                invalid, the parties nevertheless agree that the court should
                endeavor to give effect to the parties intentions as reflected
                in the provision, and the other provisions of these Terms of
                Service remain in full force and effect. You agree that
                regardless of any statute or law to the contrary, any claim or
                cause of action arising out of or related to use of the Service
                or these Terms of Service must be filed within one (1) year
                after such claim or cause of action arose or be forever barred.
                A printed version of this agreement and of any notice given in
                electronic form will be admissible in judicial or administrative
                proceedings based upon or relating to this agreement to the same
                extent and subject to the same conditions as other business
                documents and records originally generated and maintained in
                printed form. You may not assign this Terms of Service without
                the prior written consent of myFit, but myFit may assign or
                transfer this Terms of Service, in whole or in part, without
                restriction. The section titles in these Terms of Service are
                for convenience only and have no legal or contractual effect.
                Notices to you may be made via either email or regular mail. The
                Service may also provide notices to you of changes to these
                Terms of Service or other matters by displaying notices or links
                to notices generally on the Service.
</p>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
Your Privacy At myFit, we respect the privacy of our users. For
                details please see our Privacy Policy. By using the Service, you
                consent to our collection and use of personal data as outlined
                therein. To the extent you use the myFit services and access
                your third party bank accounts, you also agree to the terms of
                our provider Salt Edge located here: Salt Edge End User License
                Agreement.
</p>
<p className="text-base text-thin-light-gray leading-[28px] mb-4">
Notice for California Users Under California Civil Code Section
                1789.3, users of the Service from California are entitled to the
                following specific consumer rights notice: The Complaint
                Assistance Unit of the Division of Consumer Services of the
                California Department of Consumer Affairs may be contacted in
                writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA
                95834, or by telephone at (916) 445-1254 or (800) 952-5210. You
                may contact us at myFit, Inc., 1070 Cresta Way, Unit 2, San
                Rafael, CA 94903. +1.917.362.8893 Questions? Concerns?
                Suggestions? Please contact us at support@myFit.com to report
                any violations of these Terms of Service or to pose any
                questions regarding this Terms of Service or the Service. myFit
                . ("MERMS myFit") is committed to upholding the privacy rights
                of individuals. This Privacy Policy explains the collection,
                use, purpose, and sharing of personally identifiable information
                ("PII") related to the use of Merms's myFit website{" "}
                <span>
                  <a href={process.env.REACT_APP_APPSITE}>
                    {process.env.REACT_APP_APPSITE}
                  </a>
                </span>
                , MERMS providing of services to our users ("Partners"), or from
                our employees. PII does not include information that is
                anonymized or aggregated, and other information which is
                excluded from the scope of applicable privacy laws.
</p>
        </div>
      </div>
    </div>
  );
}
