import React from "react";
import datas from "../../data/product_data.json";
import Layout from "../Partials/Layout";

import HmLayout1 from "./HomeLayouts/HmLayout1";
import HmLayout2 from "./HomeLayouts/HmLayout2";
import HmLayout3 from "./HomeLayouts/HmLayout3";


export default function Home() {
    const trending = datas.datas;
    const main_layout =JSON.parse( localStorage.getItem("layout") ); // need to make safer - check for type first
    const recomended = main_layout.dash; // make safe 
    return (
        <Layout>
            <div className="home-page-wrapper">
                {
                  recomended=='33010'?(<HmLayout1 />):
                  recomended=='33015'?(<HmLayout2 />):
                  recomended=='33020'?(<HmLayout3 />):
                  recomended=='33025'?(<HmLayout1 />):
                  recomended=='33030'?(<HmLayout3 />):
                  recomended=='33035'?(<HmLayout2 />):
                  recomended=='33040'?(<HmLayout1 />):
                  ''
                }
            </div>
        </Layout>
    );
}
